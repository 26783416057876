import React, { useEffect, useState } from 'react'
import './Membership.css'
import { Container, Row, Col, Accordion } from 'react-bootstrap'

import { APIGET } from "../../helper/api";
import { MEMBERSHIP_URL, BASE_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet';

export default function Mebership() {
  const [membership, setMembership] = useState([])
    let param = {
      token: "hjt573v3863hjkagg2ffgmllauey8832bndab",
      limit: 15,
      offset: 0,
    }
    
  useEffect(() => {
      const fetchData = async () => {
          const res = await APIGET(MEMBERSHIP_URL, param)
          setMembership(res)
      }
      fetchData()
  }, [])

  return (
    <>
    
    <Helmet>
            <title>TDA Luxury Toys | Membership</title>
        </Helmet>
    <Container className="membership-container">
      <Row>
        <Col lg={12} xs={12} className="membership">
          <h1>Membership</h1>
        </Col>
        <Col lg={12} xs={12} className="membership-level">
          <h2>Membership Level</h2>
        </Col>
      </Row>
      {
        membership?
        membership.map((single, index) => {
          return(
            <Row className="member-card-row" key={index}>
              <Col lg={4} xs={12}>
                <img className="member-card" src={BASE_URL+single.bg_card} alt="member-card"/>
              </Col>
              <Col lg={8} xs={12}>
                <Accordion flush defaultActiveKey="0" className="member-card-accordion">
                  <Accordion.Item eventKey="0" className="member-type-card" id="member-type-card">
                    <Accordion.Header className="member-type"><p className="member-type-title">{single.name}</p></Accordion.Header>
                    <Accordion.Body >
                      <ul className="member-benefit">
                        <li>
                          <p dangerouslySetInnerHTML={{__html: single.short_benefit}}>
                          </p>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          )
        })
        :
        "<div></div>"
      }
    </Container>

    <Container fluid className="membership-faq-container">
      <Row className="justify-content-md-center">
        <Col lg={9} xs={12} className="membership-faq">
          <h1>
            FAQ
          </h1>
        </Col>
        <Col lg={9} xs={12} className="membership-faq-detail">
          <Accordion flush className="membership-faq-title-flush">
            <Accordion.Item eventKey="0" className="membership-faq-title-container">
              <Accordion.Header className="membership-faq-title"><p>What is TDA Membership ?</p></Accordion.Header>
              <Accordion.Body>
                <p className="membership-faq-description">
                You get what you give! Get gifts and ease of access for maintaining your lovely car at our TDA Service Centre & priority discount for any of TDA merchandise by becoming a member.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion flush className="membership-faq-title-flush">
            <Accordion.Item eventKey="1" className="membership-faq-title-container">
              <Accordion.Header className="membership-faq-title"><p>How to acquire TDA Membership ?</p></Accordion.Header>
              <Accordion.Body>
                <p className="membership-faq-description">
                You will get 1 point for every vehicle purchase and 0.5 points for selling a vehicle to TDA. In other words, 1 point means silver, 2 points means gold, and 3 points or more means platinum for your membership.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion flush className="membership-faq-title-flush">
            <Accordion.Item eventKey="2" className="membership-faq-title-container">
              <Accordion.Header className="membership-faq-title"><p>What are the benefits of TDA Membership ?</p></Accordion.Header>
              <Accordion.Body>
                <p className="membership-faq-description">
                As a member, you'll get discounts & installment options from BNI for periodic car maintenance & exclusive discount (up to 15%) on TDA Luxury Toys merchandise.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
    </>
  );
}
