import React from "react"
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Register from './routes/Register/Register'
import Login from './routes/Login/Login'
import ForgotPassword from './routes/ForgotPassword/ForgotPassword'
import MyProfile from './routes/MyProfile/MyProfile'
import MyGarage from './routes/MyGarage/MyGarage'
import Home from './routes/Home/Home'
import Membership from './routes/Membership/Membership'
import NewCar from './routes/NewCar/NewCar'
import UsedCar from './routes/UsedCar/UsedCar'
import CarDetail from './routes/CarDetail/CarDetail'
import SpecialRequest from './routes/SpecialRequest/SpecialRequest'
import Sell from './routes/Sell/Sell'
import News from './routes/News/News'
import NewsDetail from './routes/NewsDetail/NewsDetail'
import Events from './routes/Events/Events'
import EventsDetail from './routes/EventsDetail/EventsDetail'
import Magazine from './routes/Magazine/Magazine'
import ServiceCenter from './routes/ServiceCenter/ServiceCenter'
import Wheels from './routes/Wheels/Wheels'
import NavbarMobile from './components/NavbarMobile/NavbarMobile'
import NavbarDesktop from './components/NavbarDesktop/NavbarDesktop'
import ServicesCenter from './routes/ServiceCenter/ServiceCenter'
import BrandPage from "./routes/BrandPage/BrandPage"
import CarDetailPdf from "./routes/CarDetailPdf/CarDetailPdf"
import ReactGA from 'react-ga4'
import { MEASUREMENT_ID_REACT_GA } from "./assets/config/api"
import Privacy from "./routes/Privacy/Privacy"

ReactGA.initialize(MEASUREMENT_ID_REACT_GA)
function App() {
  return (
    <Router>
      <NavbarMobile />
      <NavbarDesktop />
      <Routes>
        <Route path='/brand/:id' exact element={<BrandPage />} />
        
        <Route path='/register' exact element={<Register />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/privacy' exact element={<Privacy />} />
        <Route path='/forgot-password' exact element={<ForgotPassword />} />
        <Route path='/my-profile' exact element={<MyProfile />} />
        <Route path='/my-garage' exact element={<MyGarage />} />

        <Route path='/' exact element={<Home />} />

        <Route path='/new-car' element={<NewCar />} />
        <Route path='/used-car' element={<UsedCar />} />
        <Route path='/car/:id' element={<CarDetail />} />
        <Route path='/car/pdf/:id' element={<CarDetailPdf />} />
        <Route path='/special-request' element={<SpecialRequest />} />
        <Route path='/sell' element={<Sell />} />

        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsDetail />} />
        <Route path='/events' element={<Events />} />
        <Route path='/events/:id' element={<EventsDetail />} />
        <Route path='/magazine' element={<Magazine />} />
        <Route path='/service-center' element={<ServiceCenter />} />
        
        <Route path='/wheels' element={<Wheels />} />

        <Route path='/membership' element={<Membership />} />
        <Route path='/service-center' element={<ServicesCenter />} />
      </Routes>
    </Router>
  )
}

export default App
