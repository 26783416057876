import React, { useEffect, useState } from 'react'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import './Login.css'
import { postEventGA } from '../../helper/helper'
import { Container, Row, Col } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import IconFacebookCircle from '../../assets/images/icon-facebook-circle.png'
import IconGoogleCircle from '../../assets/images/icon-google-circle.png'
import {useNavigate} from 'react-router-dom'
import { API_POST_AUTH, API_GET_MY_DATA} from "../../helper/api"
import { BASE_URL, LOGIN_URL, URL_MY_DATA, URL_SOCIAL_LOGIN } from "../../assets/config/api"
import { normalizeErrorMessage } from '../../helper/helper'
import { Helmet } from 'react-helmet'


export default function Login() {
    const [loginForm, setLoginForm] = useState({
        form: {
            username: {
                value: '',
            },
            password: {
                value: '',
            },
        },
    });

    const navigate = useNavigate()
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")

    const setHandleValueChange = (event) => {
        let copyForm = loginForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setLoginForm(copyForm)
    }

    const handleLoginClick = async () => {
        const formCopy = loginForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        let res = await API_POST_AUTH(LOGIN_URL, formToSubmit)
        if (res.payload.detail) {
            if(res.payload.detail === "Please enter a correct username and password. Note that both fields may be case-sensitive."){
                setGlobalErrorMessage(normalizeErrorMessage(res.payload.detail+" Or if your account already inactive, please contact 02122773115"))
            }else{
                setGlobalErrorMessage(normalizeErrorMessage(res.payload.detail))
            }
        } else {
            localStorage.setItem('session_key', res.payload.session_key)
            let res1 = await API_GET_MY_DATA(URL_MY_DATA)
            let userFormRes  = res1.payload.user
            localStorage.setItem('username', userFormRes.username)
            localStorage.setItem('first_name', userFormRes.first_name)
            localStorage.setItem('last_name', userFormRes.last_name)
            localStorage.setItem('email', userFormRes.email)
            localStorage.setItem('mobile_number', userFormRes.mobile_number)
            localStorage.setItem('short_benefit_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('name_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('image_membership', BASE_URL+userFormRes.membership.bg_card)
            localStorage.setItem('my_picture', BASE_URL+userFormRes.picture)
            localStorage.setItem('notifications', JSON.stringify(res1.payload.notifications))
            postEventGA("login/", "click", userFormRes.first_name)
            console.log(res)

            navigate('/my-profile')
        }
    }

    const socialLogin = async (accessToken, provider) => {
        if(accessToken.Cc){
            let param = {
                "provider": provider,
                "access_token": accessToken.Cc.access_token ? accessToken.Cc.access_token : "",
            }
    
            let res = await API_POST_AUTH(URL_SOCIAL_LOGIN, param)
            if (res.payload.detail) {
                if(res.payload.detail === "Please enter a correct username and password. Note that both fields may be case-sensitive."){
                    setGlobalErrorMessage(normalizeErrorMessage(res.payload.detail+" Or if your account already inactive, please contact 02122773115"))
                }else{
                    setGlobalErrorMessage(normalizeErrorMessage(res.payload.detail))
                }
            } else {
                localStorage.setItem('session_key', res.payload.session_key)
                let userFormRes  = res.payload.user
                localStorage.setItem('username', userFormRes.username)
                localStorage.setItem('first_name', userFormRes.first_name)
                localStorage.setItem('last_name', userFormRes.last_name)
                localStorage.setItem('email', userFormRes.email)
                localStorage.setItem('mobile_number', userFormRes.mobile_number)
                localStorage.setItem('short_benefit_membership', userFormRes.membership.short_benefit)
                localStorage.setItem('name_membership', userFormRes.membership.short_benefit)
                localStorage.setItem('image_membership', BASE_URL+userFormRes.membership.bg_card)
                localStorage.setItem('my_picture', BASE_URL+userFormRes.picture)
                postEventGA("login/"+param.provider, "click", param.provider)
                navigate('/my-profile') 
            }
        }else{
            setGlobalErrorMessage("Something went wrong")
        }
    }

    useEffect(() => {
        if(localStorage.getItem('session_key')){
            navigate('/my-profile')
        }
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Login</title>
        </Helmet>
        <Container>
            <Row className="login-form">
                <Col lg={12} xs={12} className="login-title">
                    <h1>Login</h1>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <FormField class_extend="col-lg-6 col-xs-12" nameField="username" val={loginForm} setHandleValueChange={setHandleValueChange}  field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
            </Row>
            <Row className="justify-content-center">
                <FormField class_extend="col-lg-6 col-xs-12" nameField="password" val={loginForm} setHandleValueChange={setHandleValueChange} field_title="PASSWORD" field_type="password" field_place_holder="Type password"/>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} xs={12} className="login-text justify-content-end">
                    <a href="/forgot-password">
                        <p>forgot password</p>
                    </a>
                </Col>
            </Row>
            <Row className="justify-content-center mt-3">
                <Col lg={6} xs={12}>
                    <p className={globalErrorMessage ? "error-message error-message-login text-justify" : "d-none"}>
                        {globalErrorMessage ? globalErrorMessage : "success"}
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-lg-center">
                <Col lg={3} xs={12}>
                    <button
                    className={"login-button button-rectangle"}
                    onClick={handleLoginClick}
                    >
                        <p>LOGIN</p>
                    </button>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={12} className="login-text justify-content-center">
                    <p>Not a member yet? <a href="/register">Register here</a></p>
                </Col>
            </Row>
        </Container>
        <Container fluid className="login-another-container">
            <Row className="justify-content-center">
                <Col lg={5} xs={12} className="login-another">
                    <p><span>OR CONTINUE WITH</span></p>
                </Col>
            </Row>
        </Container>
        <Container className="login-another-container-button">
            <Row className="justify-content-center">
                <Col lg={4} xs={12} className="login-another-facebook">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FB_ID}
                        fields="name,email,picture"
                        callback={(response) => socialLogin(response, "facebook")}
                        redirectUri='/login'
                        render={renderProps => (
                            <button className={"login-another-button button-rectangle"} onClick={renderProps.onClick}>
                                <img src={IconFacebookCircle} alt="f"/>
                                <p>Facebook Login</p>
                            </button>
                        )}
                    />
                </Col>
                <Col lg={4} xs={12} className="login-another-facebook">
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Google Login"
                        onSuccess={(response) => socialLogin(response, "google-oauth2")}
                        onFailure={(response) => console.log(response)}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <button className={"login-another-button button-rectangle"} onClick={renderProps.onClick}>
                                <img src={IconGoogleCircle} alt="g"/>
                                <p>Google Login</p>
                            </button>
                        )}
                    />
                </Col>
            </Row>
        </Container>
        </>
    );
}
