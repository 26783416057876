import React, { useEffect, useState }  from 'react'
import './MyProfile.css'
import { Form, Container, Row, Col, Accordion, Button, Modal } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import { ButtonRectangle } from '../../components/ButtonRectangle/ButtonRectangle'
import IconEditPhoto from '../../assets/images/icon-edit-photo.png'
import defaultImageMember from '../../assets/images/tda-logo-desktop-black.png'
import IconClose from '../../assets/images/icon-close.png'
import { Link } from 'react-router-dom'

import { postEventGA } from '../../helper/helper'
import { normalizeErrorMessage } from '../../helper/helper'
import { useNavigate } from 'react-router-dom'
import { APIPOST } from "../../helper/api";
import { ACCESS_TOKEN, BASE_URL, UPDATE_PROFILE_URL, DEACTIVE_ACCOUNT_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function MyProfile() {
    const [show, setShow] = useState(false)
    const [showModalInactive, setShowModalInactive] = useState(false)
    const clickModalInactive = () => {
        setShowModalInactive(!showModalInactive)
    }
    const [userForm, setUserForm] = useState({
        form: {
            username: {
                value: localStorage.getItem('username'),
            },
            first_name: {
                value: localStorage.getItem('first_name'),
            },
            last_name: {
                value: localStorage.getItem('last_name'),
            },
            email: {
                value: localStorage.getItem('email'),
            },
            mobile_number: {
                value: localStorage.getItem('mobile_number'),
            },
            current_password: {
                value: ''
            },
            password: {
                value: ''
            },
            confirm_password: {
                value: ''
            },
            picture: {
                preview: localStorage.getItem('my_picture'),
                fromDB: '',
                value: localStorage.getItem('my_picture')
            },
            imageMembership: localStorage.getItem('image_membership')
        }
    })

    const navigate = useNavigate()
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    
    const setHandleValueChange = (event) => {
        let copyForm = userForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setUserForm(copyForm)
    }
    
    const handleSubmit = async () => {
        postEventGA("my-profile", "click", "update-my-profile")
    
        const formData = new FormData()
        let formCopy = userForm
        for (let key in formCopy.form) {
            formData.append(key, formCopy.form[key].value)
        }
        formData.append('category', 'user_car')
        formData.append('session_key', localStorage.getItem("session_key") ? localStorage.getItem("session_key") : "")
        formData.append('token', ACCESS_TOKEN ? ACCESS_TOKEN : "")

        let res = await APIPOST(UPDATE_PROFILE_URL, formData)
        if (res.detail) {
            let globalErrorMessageTemp = res.detail
            setGlobalErrorMessage( globalErrorMessageTemp )
        } else {
            let userFormRes  = res.user
        
            localStorage.setItem('username', userFormRes.username)
            localStorage.setItem('first_name', userFormRes.first_name)
            localStorage.setItem('last_name', userFormRes.last_name)
            localStorage.setItem('email', userFormRes.email)
            localStorage.setItem('mobile_number', userFormRes.mobile_number)
            localStorage.setItem('short_benefit_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('name_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('image_membership', BASE_URL+userFormRes.membership.bg_card)
            localStorage.setItem('my_picture', BASE_URL+userFormRes.picture)
            setShow(true)
        }
    }

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        postEventGA("my-profile", "click", "edit-photo")
        hiddenFileInput.current.click()
    }

    const [inactiveForm] = useState({
        form: {
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : "",
            },
            session_key: {
                value: localStorage.getItem('session_key'),
            },
            username: {
                value: localStorage.getItem('username'),
            },
        }
    })
    const clickPostInactive = async () => {
        postEventGA("my-profile", "click", "inactive-account")
        const formCopy = inactiveForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        let res = await APIPOST(DEACTIVE_ACCOUNT_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            localStorage.clear()
            navigate('/login')
        }
    }

    const handleChangeImage = (event) => {
        if (event.target.files.length) {
            let copyForm = { ...userForm }
            copyForm["form"]["picture"].preview = URL.createObjectURL(event.target.files[0])
            copyForm["form"]["picture"].value = event.target.files[0]
            setUserForm(copyForm)
        }
    }
    
    useEffect(() => {
        if(!localStorage.getItem('session_key')){
            navigate('/login')
        }
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | My Profile</title>
        </Helmet>
        <Container className="my-profile-container">
            <Row className="my-profile-form">
                <Col lg={12} xs={12} className="my-profile-title">
                    <h1>My Profile</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={3} xs={12} className="my-profile-title">
                    <img className="member-card-my-profile border border-dark rounded" src={userForm.form.imageMembership !== "https://tda.dev.mplus.softwareundefined" ? userForm.form.imageMembership : defaultImageMember} alt="member-card"/>
                    <Accordion flush defaultActiveKey="0">
                        <Accordion.Item eventKey="0" className="member-type-card">
                        <Accordion.Header className="member-type" id="accordion-my-profile">
                            <p>MY PRIVILEGE</p>
                            <p className={localStorage.getItem("name_membership") === "undefined" ? "display-none" : "member-type-accordion"}>
                                {
                                    localStorage.getItem("name_membership") === "undefined" ?
                                    localStorage.getItem("name_membership")
                                    :
                                    ""
                                }
                            </p>
                        </Accordion.Header>
                        <Accordion.Body className={localStorage.getItem("name_membership") === "undefined" ? "display-none" : ""}>
                            <ul className="member-benefit-my-profile">
                                {
                                    localStorage.getItem("short_benefit_membership") === "undefined" ? 
                                    <li dangerouslySetInnerHTML={{__html: localStorage.getItem("short_benefit_membership")}}></li>
                                    :
                                    ""
                                }
                            </ul>
                        </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Link to="/my-garage" className="my-profile-my-garage">
                        <ButtonRectangle custom_style="my-profile-button" link="dont-try-this-at-home" name="MY GARAGE" onClick={() => {
                            postEventGA("my-profile", "click", "my-garage")
                        }}/>
                    </Link>
                </Col>
                
                <Col lg={1} xs={12}>
                    <Row ></Row>
                </Col>
                <Col lg={8} xs={12} className="my-profile-title">
                    <Row className="my-profile-form">
                        <Col lg={12} xs={12} className="my-profile-title">
                            <h1>PERSONAL INFORMATION</h1>
                        </Col>
                    </Row>
                    <Row className="my-profile-form">
                        <Col lg={12} xs={12} className="my-profile-photo">
                            <div className="circle">
                                <input
                                    accept="image/*"
                                    className="form-field-image"
                                    multiple
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={
                                    (event) => {
                                        handleChangeImage(event)
                                    } 
                                    }
                                />
                                <Button className="button-image-form-profile" onClick={handleClick}>
                                    {
                                        userForm.form.picture.value === "undefined" ?
                                        <div className="image-preview-container">
                                            <img src={userForm.form.picture.preview} alt="." className="preview" />
                                        </div>
                                        :
                                        localStorage.getItem("first_name") ?
                                        localStorage.getItem("first_name").charAt(0).toUpperCase()
                                        :
                                        ""
                                    }
                                </Button>
                            </div>
                            <div className="circle-image" onClick={handleClick}>
                                <img src={IconEditPhoto} alt="."/>
                            </div>
                        </Col>
                    </Row>
                    <Form>
                        <Row>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="first_name" val={userForm} setHandleValueChange={setHandleValueChange} field_title="FIRST NAME" field_type="text" field_place_holder="Type first name"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="last_name" val={userForm} setHandleValueChange={setHandleValueChange} field_title="LAST NAME" field_type="text" field_place_holder="Type last name"/>
                        </Row>
                        <Row>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="username" val={userForm} setHandleValueChange={setHandleValueChange} field_title="USERNAME" field_type="text" field_place_holder="Type username"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="mobile_number" val={userForm} setHandleValueChange={setHandleValueChange} field_title="PHONE NUMBER" field_type="text" field_place_holder="Type phone number"/>
                        </Row>
                        <Row>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="email" val={userForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                        </Row>
                    </Form>
                    <Row className="justify-content-lg-center">
                        <Col lg={12} xs={12}>
                            <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                {globalErrorMessage ? globalErrorMessage : "success"}
                            </p>
                        </Col>
                        <Col lg={4} xs={12}>
                            <button
                            className={"my-profile-button button-rectangle"}
                            onClick={handleSubmit}
                            >
                                <p>SAVE</p>
                            </button>
                        </Col>
                        <Col lg={7} xs={12}>
                            <button
                            className={"my-profile-button button-rectangle button-rectangle-danger"}
                            onClick={clickModalInactive}
                            >
                                <p>INACTIVE ACCOUNT</p>
                            </button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center my-profile-divider">
                        <Col className="col-lg-12 col-xs-12"></Col>
                    </Row>
                    <Row className="my-profile-form">
                        <Col lg={12} xs={12} className="my-profile-title">
                            <h1>CHANGE PASSWORD</h1>
                        </Col>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="current_password" val={userForm} setHandleValueChange={setHandleValueChange} field_title="CURRENT PASSWORD" field_type="password" field_place_holder="Type current password"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="password" val={userForm} setHandleValueChange={setHandleValueChange} field_title="NEW PASSWORD" field_type="password" field_place_holder="Type new password"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="confirm_password" val={userForm} setHandleValueChange={setHandleValueChange} field_title="CONFIRM NEW PASSWORD" field_type="password" field_place_holder="Retype new password"/>
                    </Row>
                    
                    <Row className="justify-content-lg-center">
                        <Col lg={4} xs={12}>
                            <button
                            className={"my-profile-button button-rectangle"}
                            onClick={handleSubmit}
                            >
                                <p>SAVE</p>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

        
        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p  className='text-center'>
                Data has been updated succesfully
                </p>
            </Modal.Body>
        </Modal>

        <Modal id="modal-close-info" show={showModalInactive} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Warning</p>
                <img src={IconClose} 
                alt="x"
                onClick={clickModalInactive}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p  className='text-center'>
                    ARE YOU SURE INACTIVE THIS ACCOUNT ?
                    <br />
                    PLEASE CONTACT CUSTOMER SERVICE (02122773115)
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                className={"my-profile-button button-rectangle button-rectangle-danger"}
                onClick={clickPostInactive}
                >
                    <p>YES</p>
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
