import React, { useState } from 'react';
import './FormField.css';
import { Form } from 'react-bootstrap'


export function FormField(props) {
  const [fieldValue, setFieldValue] = useState(props.val["form"][props.nameField]["value"])
  const handleOnchange = e => {
    setFieldValue(e.target.value)
  }

  return (
    <Form.Group className={props.class_extend == null ? "col-lg-3 col-xs-12 tda-form-control "+props.field_type : props.class_extend }>
        <Form.Label className="form-field-title">{props.field_title}*</Form.Label>
        <Form.Control 
          name={props.nameField} 
          className="form-field" 
          value={fieldValue}
          onClick={(event) => {
            props.setHandleValueChange(event)} 
          }
          onChange={(event) => {
            handleOnchange(event)
            props.setHandleValueChange(event)} 
          }
          onKeyUp={(event) => {
            handleOnchange(event)
            props.setHandleValueChange(event)} 
          }
          type={props.field_type} 
          placeholder={props.field_place_holder} 

          min={
            props.nameField === "appointment_date" ? 
            new Date().toISOString().split("T")[0]
            :
            ""
          }
          />
    </Form.Group>
  );
}
