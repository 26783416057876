import React, { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import './Privacy.css'

export default function Privacy() {
  const navigate = useNavigate()

  return (
    <>
    <Helmet>
      <title>TDA Luxury Toys | Privacy Policy</title>
    </Helmet>
    <Container>
      <Row className="login-form">
        <Col lg={12} xs={12} className="login-title">
          <h1>Privacy Policy</h1>

          <p>Effective Date: 19 November 2024</p>

          <p>Your privacy is important to us. This Privacy Policy explains how TDA.co.id ("we", "our", or "us") collects, uses, discloses, and protects your information when you use our website and mobile applications (the "Services"). By accessing or using our Services, you agree to this Privacy Policy.</p>

          <ol type='1' className='decimal-list'>
            <li>Information We Collect</li>
            <p>We collect the following types of information:</p>

            <ol type='a' className='decimal-char'>
              <li>Information You Provide to Us</li>
              <ul>
                <li><p>Personal information, such as your name, email address, phone number, and other contact details, when you register or interact with our Services.</p></li>
                <li><p>Payment information when you make a purchase or transaction.</p></li>
              </ul>
              <li>Automatically Collected Information</li>
              <ul>
                <li><p><b>Usage Data:</b> Information about your interactions with our Services, including IP address, browser type, pages viewed, and time spent.</p></li>
                <li><p><b>Device Information:</b> Details about the device used to access our Services, such as operating system and unique device identifiers.</p></li>
                <li><p><b>Cookies and Similar Technologies:</b> Data collected through cookies and tracking technologies to enhance user experience and analyze usage trends.</p></li>
              </ul>
              <li>Third-Party Sources</li>
              <p>We may receive information about you from third-party services, such as social media platforms, when you interact with our accounts or use login options from those services.</p>
            </ol>

            <li>How We Use Your Information</li>
            <p>We use the information collected for purposes including but not limited to:</p>
            <ul>
              <li><p>Providing, operating, and maintaining our Services.</p></li>
              <li><p>Processing transactions and sending confirmations.</p></li>
              <li><p>Responding to inquiries and providing customer support.</p></li>
              <li><p>Sending promotional materials and updates about our products and services.</p></li>
              <li><p>Improving our website, applications, and overall user experience.</p></li>
            </ul>
            
            <li>Sharing Your Information</li>
            <p>We may share your information with third parties under these circumstances:</p>

            <ul>
              <li><p><b>Service Providers:</b> With vendors who help us operate our Services, such as payment processors or analytics providers.</p></li>
              <li><p><b>Legal Compliance:</b> To comply with legal obligations, such as responding to lawful requests or enforcing our rights.</p></li>
              <li><p><b>Business Transfers:</b> In case of a merger, sale, or transfer of assets, your information may be part of the transaction.</p></li>
            </ul>
            <br />
            <p>We will not sell, rent, or lease your information to third parties without your consent.</p>

            <li>
              Your Rights and Choices
            </li>
            <p>You have the right to:</p>
            <ul>
              <li><p>Access, update, or delete your personal information.</p></li>
              <li><p>Opt-out of receiving marketing communications.</p></li>
              <li><p>Restrict or object to the processing of your information under certain circumstances.</p></li>
              <li><p>Withdraw consent where processing is based on your consent.</p></li>
            </ul>
            <br />
            <p>To exercise these rights, contact us at <a href='mailto:marcomm@tda.co.id'>marcomm@tda.co.id</a></p>

            <li>
              Data Security
            </li>
            <p>We take appropriate technical and organizational measures to secure your information from unauthorized access, disclosure, or misuse. However, no security system is completely impenetrable.</p>

            <li>
              Retention of Information
            </li>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</p>

            <li>
              Third-Party Links
            </li>
            <p>Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. Please review their privacy policies for more information.</p>

            <li>
              Changes to This Privacy Policy
            </li>
            <p>We may update this Privacy Policy from time to time. We will notify you of changes by updating the "Effective Date" at the top of this page. Your continued use of our Services signifies your acceptance of the updated policy.</p>

            <li>
              Contact Us
            </li>
            <p>If you have questions or concerns about this Privacy Policy or how we handle your information, please contact us:</p>

            <ul>
              <li><p><b>Email:</b> <a href='mailto:marcomm@tda.co.id'>marcomm@tda.co.id</a></p></li>
              <li><p><b>Address:</b> Jl. Kramat Pela Raya No.31, RT.1/RW.4, Kramat Pela, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130</p></li>
            </ul>
          </ol>
        </Col>
      </Row>
    </Container>
    </>
  )
}