import React, { useState } from 'react'
import './ServiceCenter.css'
import ServiceCenterBanner from '../../assets/images/service-center-banner.png'
import ServiceCenterBannerMobile from '../../assets/images/service-center-banner-mobile.png'
import IconWhatsappCircle from '../../assets/images/icon-whatsapp-circle1.png'
import IconClose from '../../assets/images/icon-close.png'
import SliderServiceCenter from '../../components/SliderServiceCenter/SliderServiceCenter'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'

import { postEventGA } from '../../helper/helper'
import { normalizeErrorMessage } from '../../helper/helper'
import { APIPOST } from "../../helper/api"
import { ACCESS_TOKEN, CAR_ENQUIRY_URL } from "../../assets/config/api"
import { Helmet } from 'react-helmet'

export default function ServiceCenter() {
    const [sellForm, setSellForm] = useState({
        form: {
            id: {
                value: '',
            },
            manufacturer: {
                value: '',
            },
            serie: {
                value: '',
            },
            year: {
                value: '',
            },
            color: {
                value: '',
            },
            engine: {
                value: '',
            },
            email: {
                value: '',
            },
            mobile_number: {
                value: '',
            },
            mileage: {
                value: '',
            },
            price: {
                value: '',
            },
            description: {
                value: '',
            },
            tax_year: {
                value: '',
                origin: ''
            },
            appointment_date: {
                value: new Date(),
                origin: ''
            },
            plate_number: {
                value: '',
            },
            inspection_location: {
                value: '',
            },
            type_service: {
                value: '',
            },
            full_name: {
                value: 
                localStorage.getItem("first_name") ?
                localStorage.getItem("first_name")+" "+localStorage.getItem("last_name")
                :
                "",
            },
            image_1: {
                preview: '',
                id: '',
                value: ''
            },
            image_2: {
                preview: '',
                id: '',
                value: ''
            },
            image_3: {
                preview: '',
                id: '',
                value: '',
            },
            image_4: {
                preview: '',
                id: '',
                value: '',
            },
            image_5: {
                preview: '',
                id: '',
                value: '',
            },
            image_6: {
                preview: '',
                id: '',
                value: '',
            },
            image_7: {
                preview: '',
                id: '',
                value: '',
            },
            image_8: {
                preview: '',
                id: '',
                value: '',
            },
            image_9: {
                preview: '',
                id: '',
                value: '',
            },
            image_10: {
                preview: '',
                id: '',
                value: '',
            },
            delete_image_ids: {
                value: ''
            },
            session_key: {
                value: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : ""
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        },
    })

    const [show, setShow] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    const [displayForm, setDisplayForm] = useState(false)

    const setHandleValueChange = (event) => {
        let copyForm = sellForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setSellForm(copyForm)

        setDisplayForm(true)
    }

    const handleSubmit = async () => {
        const formCopy = sellForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        formToSubmit['category'] = 'restoration_car'
        let res = await APIPOST(CAR_ENQUIRY_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            postEventGA("service-center", "click", 'restoration-car')
            setShow(true)
        }
    }

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Service Center</title>
        </Helmet>
        <Container fluid className="service-center-banner">
            <Row>
                <Col xs={12}>
                    <img className="desktop" src={ServiceCenterBanner} alt="service center"/>
                    <img className="mobile" src={ServiceCenterBannerMobile} alt="service center"/>
                </Col>
            </Row>
        </Container>
        <Container fluid className="service-center-background-content">
            <Container>
                <Row className="service-center-form">
                    <Col lg={12} xs={12} className="service-center-title">
                        <h1>Book your service appointment now</h1>
                    </Col>
                    <Row>
                        <FormField  nameField="full_name" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="FULLNAME" field_type="text" field_place_holder="Type full name" />
                        <FormField  nameField="email" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                        <FormField  nameField="mobile_number" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="MOBILE NUMBER" field_type="text" field_place_holder="Type mobile number"/>
                        <FormField  nameField="manufacturer" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="BRAND / MANUFACTURE" field_type="text" field_place_holder="Type brand / manufacture"/>
                        <FormField class_extend={displayForm ? "col-lg-3 col-xs-12 " : "display-none"} nameField="serie" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="SERIES" field_type="text" field_place_holder="Type series"/>
                        <FormField class_extend={displayForm ? "col-lg-3 col-xs-12 " : "display-none"}  nameField="plate_number" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="PLAT NUMBER" field_type="text" field_place_holder="Type plat number"/>
                        <FormField class_extend={displayForm ? "col-lg-3 col-xs-12 " : "display-none"}  nameField="inspection_location" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="INSPECTION LOCATION" field_type="text" field_place_holder="Type inspection location"/>
                        <FormField class_extend={displayForm ? "col-lg-3 col-xs-12 " : "display-none"}  nameField="type_service" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="TYPE OF SERVICE" field_type="text" field_place_holder="Type of service"/>
                        <FormField class_extend={displayForm ? "col-lg-3 col-xs-12 " : "display-none"}  nameField="appointment_date" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="APPOINTMENT DATE" field_type="date" field_place_holder=""/>

                    </Row>
                    <Row className={displayForm ? "justify-content-lg-center" : "display-none"}>
                        <Col lg={12} xs={12}>
                            <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                {globalErrorMessage ? globalErrorMessage : "success"}
                            </p>
                        </Col>
                        <Col lg={3} xs={12}>
                            <button
                            className={"service-center-button button-rectangle"}
                            onClick={handleSubmit}
                            >
                                <p>BOOK NOW</p>
                            </button>
                            <p className="service-center-contact-us" link="dont-try-this-at-home">Need Help? Contact Us</p>
                        </Col>
                    </Row>
                </Row>
                <SliderServiceCenter/>
            </Container>
        </Container>
        <Container fluid className="service-center-background-bottom">
            <Container>
                <Row>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Col lg={12} xs={12} className="service-center-maps">
                                <h2>
                                    Location
                                </h2>
                                <Col lg={12} xs={12} className="service-center-maps home-map">
                                    <div>
                                    <iframe 
                                    title="TDA SERVICE CENTRE"
                                    id="iframe-service-center"
                                    width="100%"
                                    height="100%"
                                    src={`https://maps.google.com/maps?q=-6.251211,106.791704&hl=es;&output=embed`}
                                    >
                                    </iframe>
                                    </div>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={2} xs={12}>
                    </Col>
                    <Col lg={4} xs={12}>
                        <Row>
                            <Col lg={12} xs={12} className="service-center-address">
                                <h2>
                                    TDA SERVICE CENTRE
                                </h2>
                                <p>
                                    Jl. Radio Dalam Raya No.15, RT.10/RW.8,<br/>
                                    Gandaria Utara, Kec. Kby. Baru,<br/>
                                    Kota Jakarta Selatan,<br/>
                                    Daerah Khusus Ibukota Jakarta 12140.<br/>
                                    Tlp (021) 2709 6499
                                </p>
                                <h3>
                                    Office Hours
                                </h3>
                                <p>
                                    Monday - Friday 08.00 - 16.00<br/> Saturday 08.00 - 13.00
                                </p>
                                <h3>
                                    Need help?
                                </h3>
                                <div className="service-center-button-whatsapp">
                                    <a rel="noopener noreferrer" href={"https://api.whatsapp.com/send?phone=+6281386595922&text=hi,%20TDA"} target="_blank">
                                        <img src={IconWhatsappCircle} alt="whatsapp"/>
                                        <p>
                                        +6281386595922
                                        </p>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
        
        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>
                Thank you for submit,<br />
                Our sales will contact you soon
                </p>
            </Modal.Body>
        </Modal>
        </>
    );
}
