import React, { useEffect, useState } from 'react'
import './ForgotPassword.css'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import IconClose from '../../assets/images/icon-close.png'
import { FormField } from '../../components/FormField/FormField'
import ArrowIcon from '../../assets/images/icon-arrow.png'
import { Link } from 'react-router-dom'
import { normalizeErrorMessage } from '../../helper/helper'

import { postEventGA } from '../../helper/helper'
import { APIPOST } from "../../helper/api";
import { RESET_PASS_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'


export default function ForgotPassword  () {
    
    const [show, setShow] = useState(false)
    const [forgotPasswordForm, setForgotPasswordForm] = useState({
        form: {
            email: {
                value: ''
            },
        }
    });

    const [globalErrorMessage, setGlobalErrorMessage] = useState("");
    
    const setHandleValueChange = (event) => {
        let copyForm = forgotPasswordForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setForgotPasswordForm(copyForm)
    }

    const handleResetClick = async () => {
        const formCopy = forgotPasswordForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        let res = await APIPOST(RESET_PASS_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            postEventGA("forgot-password", "click", formToSubmit.email)
            setShow(true)
        }
    }

    useEffect(() => {
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Forgot Password</title>
        </Helmet>
        <Container className="forgot-password-container">
            <Row className="forgot-password-form">
                <Col lg={12} xs={12} className="forgot-password-back">
                    <p>
                        <Link to='/login'>
                            <img src={ArrowIcon} alt="." className="icon-arrow"/>
                            LOGIN
                        </Link>
                    </p>
                </Col>
                <Col lg={12} xs={12} className="forgot-password-title">
                    <h1>Forgot Password</h1>
                    <p>Verification code to reset your password, will be send to your email</p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <FormField class_extend="col-lg-6 col-xs-12" nameField="email" val={forgotPasswordForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
            </Row>
            <Row className="justify-content-center">
                <Col lg={6} xs={12}>
                    <p className={globalErrorMessage ? "error-message error-message-login" : "text-opacity-zero"}>
                        {globalErrorMessage ? globalErrorMessage : "success"}
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={3} xs={12}>
                <button
                className="forgot-password-button button-rectangle"
                onClick={handleResetClick}
                >
                    <p>RESET</p>
                </button>
                </Col>
            </Row>
        </Container>

        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.href="/login"}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p  className='text-center'>
                Forget password successfully,<br />
                Please check your email
                </p>
            </Modal.Body>
        </Modal>
        </>
    );
}
