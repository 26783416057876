import React, { useEffect, useState } from 'react'
import './MyGarage.css'
import { Form, Container, Row, Col, Modal } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import IconPlus from '../../assets/images/icon-plus.png'
import IconClose from '../../assets/images/icon-close.png'
import { useNavigate } from 'react-router-dom'
import { postEventGA } from '../../helper/helper'

import { FormFieldImage } from '../../components/FormFieldImage/FormFieldImage'
import MyGarageCardCard from '../../components/MyGarageCarCard/MyGarageCardCard'

import { normalizeErrorMessage } from '../../helper/helper'
import { APIGET, APIPOSTFILES, APIPOST } from "../../helper/api";
import { ACCESS_TOKEN, URL_MY_DATA, BASE_URL, ADD_CAR_URL, EDIT_CAR_URL, DELETE_CAR_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function MyGarage() {  
    const [modalShow, setModalShow] = useState(false)
    const [modalShowEdit, setModalShowEdit] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    const [show, setShow] = useState(false)
    const [myCar, setMyCar] = useState([])
    
    let param = {
        session_key: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : "",
        token: ACCESS_TOKEN ? ACCESS_TOKEN : "",
    }

    const handleEditClick = (event) => {
        setModalShowEdit(true)
        let idx = event.target.attributes.index_car.nodeValue
        
        let copyForm = myGarageCarFormEdit
        copyForm["form"]["id"]["value"] =  myCar[idx].id
        copyForm["form"]["manufacturer_str"]["value"] = myCar[idx].manufacturer_str
        copyForm["form"]["serie"]["value"] = myCar[idx].serie
        copyForm["form"]["year"]["value"] = myCar[idx].year
        copyForm["form"]["exterior_colour"]["value"] = myCar[idx].exterior_colour
        copyForm["form"]["engine"]["value"] = myCar[idx].engine
        copyForm["form"]["last_service"]["value"] = myCar[idx].last_service
        copyForm["form"]["tax_year"]["value"] = myCar[idx].tax_year
        copyForm["form"]["image_1"]["preview"] = BASE_URL+myCar[idx].picture
        copyForm["form"]["image_1"]["value"] = BASE_URL+myCar[idx].picture

        if(myCar[idx].pictures){
            if(myCar[idx].pictures[0]){
                copyForm["form"]["image_2"]["preview"] = BASE_URL+myCar[idx].pictures[0].picture
                copyForm["form"]["image_2"]["value"] = BASE_URL+myCar[idx].pictures[0].picture
            }
            if(myCar[idx].pictures[1]){
                copyForm["form"]["image_3"]["preview"] = BASE_URL+myCar[idx].pictures[1].picture
                copyForm["form"]["image_3"]["value"] = BASE_URL+myCar[idx].pictures[1].picture
            }
        }
        setMyGarageCarFormEdit(copyForm)
    }

    let param_delete = {
        car_id: 0,
        session_key: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : "",
        token: ACCESS_TOKEN ? ACCESS_TOKEN : "",
    }

    const onClickDelete = async (id) => {
        let formToSubmit = param_delete
        formToSubmit.car_id = id
        let res = await APIPOST(DELETE_CAR_URL, param_delete)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            setShow(true)
        }
    }

    const [myGarageCarFormEdit, setMyGarageCarFormEdit] = useState({
        form: {
            id: {
                value: 0,
            },
            manufacturer_str: {
                value: '',
            },
            serie: {
                value: '',
            },
            year: {
                value: '',
            },
            exterior_colour: {
                value: '',
            },
            engine: {
                value: '',
            },
            last_service: {
                value: new Date(),
                origin: ''
            },
            tax_year: {
                value: new Date(),
                origin: ''
            },
            image_1: {
                preview: '',
                id: '',
                value: ''
            },
            image_2: {
                preview: '',
                id: '',
                value: ''
            },
            image_3: {
                preview: '',
                id: '',
                value: '',
            },
            delete_image_ids: {
                value: ''
            },
            session_key: {
                value: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : ""
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        },
    })
    
    const setHandleValueChange = (event) => {
        let copyForm = myGarageCarFormEdit
        copyForm["form"][event.target.name]["value"] = event.target.value
        setMyGarageCarFormEdit(copyForm)
    }
    
    const handleChangeImage = (event) => {
        if (event.target.files.length) {
            let copyForm = { ...myGarageCarFormEdit }
            copyForm["form"][event.target.id].preview = URL.createObjectURL(event.target.files[0])
            copyForm["form"][event.target.id].value = event.target.files[0]
            setMyGarageCarFormEdit(copyForm)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (myGarageCarFormEdit.form.image_1.preview === "") {
            setGlobalErrorMessage('Cover image is required')
            return;
        } else if (myGarageCarFormEdit.form.manufacturer_str.value === '') {
            setGlobalErrorMessage('Brand is required')
            return;
        }
    
        const formData = new FormData()
        let formCopy = myGarageCarFormEdit
        for (let key in formCopy.form) {
            formData.append(key, formCopy.form[key].value)
        }
        formData.append('category', 'user_car')
        formData.append('session_key', localStorage.getItem("session_key") ? localStorage.getItem("session_key") : "")
        let url = EDIT_CAR_URL

        let res = await APIPOSTFILES(url, formData)
        if (res.detail) {
            let globalErrorMessageTemp = res.detail
            setGlobalErrorMessage( globalErrorMessageTemp )
        } else {
            window.location.reload(false)
        }
    }

    const navigate = useNavigate()
    useEffect(() => {
        if(!localStorage.getItem('session_key')){
            navigate('/login')
        }
        const fetchData = async () => {
            const res = await APIGET(URL_MY_DATA, param)
            setMyCar(res["garrage_car"])
        }
        fetchData()
    }, [])
    
    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | My Garage</title>
        </Helmet>
        <Container className="my-garage-container">
            <Row className="my-garage-form">
                <Col lg={12} xs={12} className="my-garage-title" >
                    <h1>My Garage</h1>
                    <button
                    className={"my-garage-button-desktop button-rectangle"}
                    onClick={() => setModalShow(true)}
                    >
                        <p>ADD MY CAR</p>
                    </button>
                    <img className="my-garage-button-mobile" src={IconPlus} alt="+" onClick={() => setModalShow(true)}/>
                </Col>
            </Row>
            <Row>
                {
                    myCar.map((my_card, index) => {
                        return(
                            <Col lg={4} xs={12} className="my-garage-car" key={index}>
                                <MyGarageCardCard onClickEdit={handleEditClick} onClickDelete={onClickDelete} index_car={index} url_image={BASE_URL + my_card.picture} car_id={my_card.id} year={my_card.year} color={my_card.exterior_colour} engine={my_card.engine} title={my_card.manufacturer_str}/>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
        <Container fluid>
            <ModalMyGarage
                fullscreen={true}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <Modal fullscreen={true} show={modalShowEdit}>
                <Modal.Header className="my-garage-modal">
                    <Modal.Title>
                        <img src={IconClose} 
                        alt="x"
                        onClick={() => setModalShowEdit(false)}
                        className="tda-search-close"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="my-garage-form justify-content-center">
                            <Col lg={12} xs={12} className="my-garage-title" id="my-garage-modal-title" >
                                <h1>Edit My Car</h1>
                            </Col>
                        </Row>
                        <Row className="mygarage-form-container">
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="manufacturer_str" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="BRAND" field_type="text" field_place_holder="Type brand"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="serie" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="SERIES" field_type="email" field_place_holder="Type series"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="year" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="YEAR" field_type="text" field_place_holder="YYYY"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="exterior_colour" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="COLOR" field_type="text" field_place_holder="Type color"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="engine" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="ENGINE" field_type="text" field_place_holder="Type engine"/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="last_service" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="LAST SERVICE" field_type="date" field_place_holder=""/>
                            <FormField class_extend="col-lg-6 col-xs-12" nameField="tax_year" val={myGarageCarFormEdit} setHandleValueChange={setHandleValueChange} field_title="STNK / TAX EXPIRED" field_type="date" field_place_holder=""/>
                            <Col lg={6} xs={12} ></Col>
                            <Col lg={12} xs={12} >
                                <Form.Label className="form-field-title form-field-label">CAR IMAGES* (first photo is automatically set as cover image)</Form.Label>
                            </Col>
                            <Col lg={2} xs={4} >
                                <FormFieldImage 
                                    nameField="image_1"
                                    val={myGarageCarFormEdit}
                                    path={myGarageCarFormEdit.form.image_1.preview}
                                    handleChangeImage={handleChangeImage}
                                />
                            </Col>
                            <Col lg={2} xs={4} >
                                <FormFieldImage 
                                    nameField="image_2"
                                    val={myGarageCarFormEdit}
                                    path={myGarageCarFormEdit.form.image_2.preview}
                                    handleChangeImage={handleChangeImage}
                                />
                            </Col>
                            <Col lg={2} xs={4} >
                                <FormFieldImage 
                                    nameField="image_3"
                                    val={myGarageCarFormEdit}
                                    path={myGarageCarFormEdit.form.image_3.preview}
                                    handleChangeImage={handleChangeImage}
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-lg-center">
                            <Col lg={12} xs={12}>
                                <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                    {globalErrorMessage ? globalErrorMessage : "success"}
                                </p>
                            </Col>
                            <Col lg={4} xs={12}>
                                <button
                                className={"my-garage-button button-rectangle"}
                                onClick={handleSubmit}
                                >
                                    <p>SAVE</p>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Container>

        
        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>
                The car has been deleted succcessfuly
                </p>
            </Modal.Body>
        </Modal>
        </>
    );
}

function ModalMyGarage(props) {
    const [editMode] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    
    const [myGarageCarForm, setMyGarageCarForm] = useState({
        form: {
            id: {
                value: '',
            },
            manufacturer_str: {
                value: 'bbb',
            },
            serie: {
                value: 'bbb',
            },
            year: {
                value: '2021',
            },
            exterior_colour: {
                value: 'bbb',
            },
            engine: {
                value: 'bbb',
            },
            last_service: {
                value: new Date(),
                origin: ''
            },
            tax_year: {
                value: new Date(),
                origin: ''
            },
            image_1: {
                preview: '',
                id: '',
                value: ''
            },
            image_2: {
                preview: '',
                id: '',
                value: ''
            },
            image_3: {
                preview: '',
                id: '',
                value: '',
            },
            delete_image_ids: {
                value: ''
            },
            session_key: {
                value: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : ""
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        },
    })
    
    const setHandleValueChange = (event) => {
        let copyForm = myGarageCarForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setMyGarageCarForm(copyForm)
    }
    
    const handleChangeImage = (event) => {
        if (event.target.files.length) {
            let copyForm = { ...myGarageCarForm }
            copyForm["form"][event.target.id].preview = URL.createObjectURL(event.target.files[0])
            copyForm["form"][event.target.id].value = event.target.files[0]
            setMyGarageCarForm(copyForm)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (myGarageCarForm.form.image_1.preview === "") {
            setGlobalErrorMessage('Cover image is required')
            return;
        } else if (myGarageCarForm.form.manufacturer_str.value === '') {
            setGlobalErrorMessage('Brand is required')
            return;
        }
    
        const formData = new FormData()
        let formCopy = myGarageCarForm
        for (let key in formCopy.form) {
            formData.append(key, formCopy.form[key].value)
        }
        formData.append('category', 'user_car')
        formData.append('session_key', localStorage.getItem("session_key") ? localStorage.getItem("session_key") : "")
        let url = ADD_CAR_URL
        if (editMode) {
            url = EDIT_CAR_URL
        }

        let res = await APIPOSTFILES(url, formData)
        if (res.detail) {
            let globalErrorMessageTemp = res.detail
            setGlobalErrorMessage( globalErrorMessageTemp )
        } else {
            postEventGA("my-garage", "click", 'add-user-car')
            window.location.reload(true)
        }
    }

    return (
        <Modal 
            {...props} fullscreen={true}>
            <Modal.Header closeButton className="my-garage-modal">
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="my-garage-form justify-content-center">
                        <Col lg={12} xs={12} className="my-garage-title" id="my-garage-modal-title" >
                            <h1>Add My Car</h1>
                        </Col>
                    </Row>
                    <Row className="mygarage-form-container">
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="manufacturer_str" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="BRAND" field_type="text" field_place_holder="Type brand"/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="serie" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="SERIES" field_type="email" field_place_holder="Type series"/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="year" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="YEAR" field_type="text" field_place_holder="YYYY"/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="exterior_colour" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="COLOR" field_type="text" field_place_holder="Type color"/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="engine" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="ENGINE" field_type="text" field_place_holder="Type engine"/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="last_service" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="LAST SERVICE" field_type="date" field_place_holder=""/>
                        <FormField class_extend="col-lg-6 col-xs-12" nameField="tax_year" val={myGarageCarForm} setHandleValueChange={setHandleValueChange} field_title="STNK / TAX EXPIRED" field_type="date" field_place_holder=""/>
                        <Col lg={6} xs={12} ></Col>
                        <Col lg={12} xs={12} >
                            <Form.Label className="form-field-title form-field-label">CAR IMAGES* (first photo is automatically set as cover image)</Form.Label>
                        </Col>
                        <Col lg={2} xs={4} >
                            <FormFieldImage 
                                nameField="image_1"
                                val={myGarageCarForm}
                                path={myGarageCarForm.form.image_1.preview}
                                handleChangeImage={handleChangeImage}
                            />
                        </Col>
                        <Col lg={2} xs={4} >
                            <FormFieldImage 
                                nameField="image_2"
                                val={myGarageCarForm}
                                path={myGarageCarForm.form.image_2.preview}
                                handleChangeImage={handleChangeImage}
                            />
                        </Col>
                        <Col lg={2} xs={4} >
                            <FormFieldImage 
                                nameField="image_3"
                                val={myGarageCarForm}
                                path={myGarageCarForm.form.image_3.preview}
                                handleChangeImage={handleChangeImage}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-lg-center">
                        <Col lg={12} xs={12}>
                            <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                {globalErrorMessage ? globalErrorMessage : "success"}
                            </p>
                        </Col>
                        <Col lg={4} xs={12} >
                            <button
                            className={"my-garage-button button-rectangle"}
                            onClick={handleSubmit}
                            >
                                <p>SAVE</p>
                            </button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}