import React, { useEffect, useState }  from 'react'
import './Magazine.css'
import { Container, Row, Col } from 'react-bootstrap'
import { MagazineCard } from '../../components/MagazineCard/MagazineCard'

import { APIGET } from "../../helper/api";
import { MAGAZINE_URL, BASE_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet';

export default function Magazine() {
    const [magazine, setMagazine] = useState([])
    let param = {
        limit: 15,
        offset: 0,
    }

    
    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(MAGAZINE_URL, param)
            setMagazine(res)
        }
        fetchData()
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Magazine</title>
        </Helmet>
        <Container>
            <Row>
                <Col lg={4} xs={12} className="magazine-title">
                    <h1>Magazine</h1>
                </Col>
            </Row>
            <Row>
                {
                    magazine?
                    magazine.map((single, index) => {
                    return(
                        <MagazineCard ket={index} title={single.title} source_image={BASE_URL+single.picture} source_pdf={BASE_URL+single.pdf_file}/>
                    )
                    })
                    :
                    ""
                }
            </Row>
        </Container>
        </>
    );
}
