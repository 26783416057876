import React from 'react';
import './DiscoverCard.css';
import { Col } from 'react-bootstrap'
import { getDateFormat } from '../../helper/helper'
import { postEventGA } from '../../helper/helper'


export function DiscoverCard(props) {
  return (
    <Col lg={4} xs={12} className="news-card">
      <a href={props.card_type+props.slug} onClick={() => {
        postEventGA("discover"+props.card_type, "click", props.title)
      }}>
        <div className="card-discover-img">
          <img src={process.env.REACT_APP_BASE_URL+props.picture} alt="new-car"/>
        </div>
        <div>
            <p>{getDateFormat(props.updated_at)}</p>
            <h2 title={props.title}>{props.title}</h2>
        </div>
      </a>
    </Col>
  );
}
