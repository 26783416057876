import React, { useEffect, useState } from 'react'
import './UsedCar.css'
import { Container, Row, Col } from 'react-bootstrap'
import UsedCarBanner from '../../assets/images/used-car-banner.jpg'
import UsedCarBannerMobile from '../../assets/images/used-car-banner.jpg'
import { CarCard } from '../../components/CarCard/CarCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { APIGET } from "../../helper/api";
import { CAR_URL, BASE_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function UsedCar() {
    
    const [cars, setCar] = useState([])
    const [endCars, setEndCars] = useState(false)
    let count = 0
    let param = {
        limit: 12,
        offset: 0,
        category: "used",
    }

    const fetchData = async () => {
        if(!endCars){
            const res = await APIGET(CAR_URL, param)
            if(res.length === count){
                setEndCars(true)
            }else{
                setCar(res)
                count = res.length
            }
        }
    }

    const handleNavigation = () => {
        if(window.innerHeight > document.getElementById('bottom-container').getBoundingClientRect().bottom - 190){
            param.limit+=3
            fetchData()
        }
    }
    
    useEffect(() => {
        window.addEventListener("scroll", handleNavigation)
        fetchData()
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Used Cars</title>
        </Helmet>
        <Container fluid className="used-car-banner">
            <Row>
                <Col xs={12}>
                    <img className="desktop" src={UsedCarBanner} alt="used-car"/>
                    <img className="mobile" src={UsedCarBannerMobile} alt="used-car"/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col lg={12} xs={12} className="used-car-title">
                    <h1>Used Cars</h1>
                </Col>
            </Row>
            <Row className="used-car-card">
                {
                    cars.map((car, index) => {
                        return(
                            <CarCard src_image={BASE_URL+car.picture} title={car.serie} link_detail={car.slug} key_car={index} key={index}/>
                        )
                    })
                }
            </Row>
            <Row className={endCars ? "new-car-card display-none" : "new-car-card"}  id="bottom-container">
                {(() => {
                    const options = []
                    for (let i = 0; i < 6; i++) {
                        options.push(
                            <Col lg={4} xs={12} className={`car-card-skeleton ${i > 0 && 'd-none d-sm-flex'}`}>
                                <div className="car-card-img">
                                <Skeleton className="car-card-skeleton-img"/>
                                <Skeleton height={20} className="car-card-skeleton-title" />
                                </div>
                            </Col>
                        )
                    }
                    return options
                })()}
            </Row>
        </Container>
        </>
    );
}
