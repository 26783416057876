export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ACCESS_TOKEN = process.env.REACT_APP_TOKEN
export const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK
export const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM
export const TOKOPEDIA_URL = process.env.REACT_APP_TOKOPEDIA
export const MAPS_URL = process.env.REACT_APP_MAPS
export const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP
export const GOOGLE_URL = process.env.REACT_APP_GOOGLE
export const APPLE_URL = process.env.REACT_APP_APPLE
export const BACK_OFFICE = process.env.REACT_APP_BACK_OFFICE

export const MEASUREMENT_ID_REACT_GA = 'G-BRQPTGGXHE'

export const URL_PUBLIC_DATA = BASE_URL + "/api/public/public-data"
export const URL_MY_DATA = BASE_URL + "/api/auth/my-data"
export const CAR_URL = BASE_URL + "/api/public/car"
export const KEYWORD_URL = BASE_URL + "/api/public/keyword"
export const MAGAZINE_URL = BASE_URL + "/api/public/magazine"
export const EVENT_URL = BASE_URL + "/api/public/event"
export const VIDEO_URL = BASE_URL + "/api/public/video"
export const NEWS_URL = BASE_URL + "/api/public/article"
export const BANNER_URL = BASE_URL + "/api/public/home-banner"
export const MEMBERSHIP_URL = BASE_URL + "/api/public/membership"
export const BRAND_URL = BASE_URL + "/api/public/brand"
export const MERCHANDISE_URL = BASE_URL + "/api/public/merchandise"
export const NOTIFICATION_URL = BASE_URL + "/api/auth/notification/mark-all-as-read/"

// POST
export const LOGIN_URL = BASE_URL + "/api/auth/login"
export const URL_SOCIAL_LOGIN = BACK_OFFICE + "/api/oauth/login/"
export const DEACTIVE_ACCOUNT_URL = BASE_URL + "/api/auth/deactivate"
export const REGISTER_URL = BASE_URL + "/api/auth/register"
export const CAR_ENQUIRY_URL = BASE_URL + "/api/public/car-enquiry"
export const CAR_ENQUIRY_DETAIL_URL = BASE_URL + "/api/public/inquiry-car"
export const ADD_CAR_URL = BASE_URL + "/api/member/add-car"
export const EDIT_CAR_URL = BASE_URL + "/api/member/edit-car"
export const DELETE_CAR_URL = BASE_URL + "/api/member/delete-car"
export const RESET_PASS_URL = BASE_URL + "/api/auth/reset-password/";
export const CONFIRM_RESET_PASS_URL = BASE_URL + "/api/auth/confirm-reset-password/";
export const UPDATE_PROFILE_URL = BASE_URL + "/api/auth/update-profile"
export const GETSET_FAVOURITE_CAR_URL = BASE_URL + "/api/member/set-or-remove-favourite-car"
export const JOINT_EVENT_URL = BASE_URL + "/api/member/join-event"
export const CONTACT_US = BASE_URL + "/api/public/contact-us"