import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import './SliderBanner.css'

import { APIGET } from "../../helper/api";
import { BANNER_URL, BASE_URL } from "../../assets/config/api";

function SliderBanner(){
  const [banners, setBanner] = useState([])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: dots => (
      <div>
        <ul className="slider-dot-banner-ul" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className="slider-dot-banner">
      </div>
    )
  };

  let param = {
    limit: 1000,
    offset: 0,
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await APIGET(BANNER_URL, param)
      setBanner(res)
    }
    fetchData()
  }, [])

  return (
    <>
      <Slider className="slider-banner " {...settings}>
        {
          banners ?
          banners.map((banner, index) => {
            return(
              <div className="slider-banner-container" key={index}>
                <div className="slider-banner-image" alt="banner"
                  style={{ 
                    backgroundImage: `url(${BASE_URL+banner.picture})` 
                  }}></div>
              </div>
            )
          })
          :
          <div></div>
        }
      </Slider>
    </>
  );
}

export default SliderBanner