import React from 'react';
import './FormFieldImage.css';
import { Button } from 'react-bootstrap'
import IconPlus from '../../assets/images/icon-plus.png'


export function FormFieldImage(props) {
  const hiddenFileInput = React.useRef(null)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }
  
  return (
    <div>
      <input
        accept="image/*"
        className="form-field-image"
        id={props.nameField}
        multiple
        type="file"
        ref={hiddenFileInput}
        onChange={
          (event) => {
            // handleOnchange(event)
            props.handleChangeImage(event)} 
        }
      />
      <Button className="button-image-form" onClick={handleClick}>
        {props.path ?
          <div className="image-preview-container">
            <img src={props.path} alt="." className="preview" />
          </div>
          :
          <div className="image-icon-plus-container">
            <img src={IconPlus} alt="."/>
          </div>
        }
      </Button>
    </div>
  );
}
