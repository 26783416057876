import React, { useEffect, useState } from 'react'
import './News.css'
import { Container, Row, Col } from 'react-bootstrap'
import { DiscoverCard } from '../../components/DiscoverCard/DiscoverCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { APIGET } from "../../helper/api";
import { NEWS_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function News() {
    const [ends, setEnd] = useState(false)
    const [news, setNews] = useState([])
    let count = 0
    let param = {
        limit: 15,
        offset: 0,
    }

    const fetchData = async () => {
        if(!ends){
            const res = await APIGET(NEWS_URL, param)
            if(res.length === count){
                setEnd(true)
            }else{
                setNews(res)
                count = res.length
            }
        }
    }

    const handleNavigation = () => {
        if(window.innerHeight > document.getElementById('bottom-container').getBoundingClientRect().bottom - 220){
            param.limit+=3
            fetchData()
        }
    }
    
    useEffect(() => {
        fetchData()
        window.addEventListener("scroll", handleNavigation)
    }, [])

    return (
        <>
            <Helmet>
                <title>TDA Luxury Toys | News</title>
            </Helmet>
            <Container className="news-container">
                <Row>
                    <Col lg={12} xs={12} className="news-title">
                        <h1>News</h1>
                    </Col>
                </Row>
                <Row>
                    {
                        news ?
                        news.map((single_news, index) => {
                            return(
                                <DiscoverCard updated_at={single_news.updated_at} 
                                title={single_news.title} 
                                picture={single_news.picture}
                                slug={single_news.slug} 
                                key={index}
                                card_type="/news/"
                                />
                            )
                        })
                        :
                        ""
                    }
                </Row>
                <Row className={ends ? "new-car-card display-none" : "new-car-card"}  id="bottom-container">
                    {(() => {
                        const options = []
                        for (let i = 0; i < 6; i++) {
                            options.push(
                                <Col lg={4} xs={12} className={`car-card-skeleton ${i > 0 && 'd-none d-sm-flex'}`}>
                                    <div className="car-card-img">
                                    <Skeleton className="car-card-skeleton-img"/>
                                    <Skeleton height={20} className="car-card-skeleton-title" />
                                    </div>
                                </Col>
                            )
                        }
                        return options
                    })()}
                </Row>
            </Container>
        </>
    );
}
