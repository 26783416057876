import React, { useEffect, useState } from 'react'
import './NewCar.css'
import { Container, Row, Col } from 'react-bootstrap'
import NewCarBanner from '../../assets/images/new-car-banner.jpg'
import NewCarBannerMobile from '../../assets/images/new-car-banner-mobile.jpg'
import { CarCard } from '../../components/CarCard/CarCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { APIGET } from "../../helper/api";
import { CAR_URL, BASE_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function NewCar() {

    const [cars, setCar] = useState([])
    const [endCars, setEndCars] = useState(false)
    let count = 0
    let param = {
        limit: 12,
        offset: 0,
        category: "new",
    }

    const fetchData = async () => {
        if(!endCars){
            const res = await APIGET(CAR_URL, param)
            if(res.length === count){
                setEndCars(true)
            }else{
                setCar(res)
                count = res.length
            }
        }
    }

    const handleNavigation = () => {
        if(window.innerHeight > document.getElementById('bottom-container').getBoundingClientRect().bottom - 190){
            param.limit+=3
            fetchData()
        }
    }

    useEffect(() => {
        fetchData()
        window.addEventListener("scroll", handleNavigation)
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | New Cars</title>
        </Helmet>
        <Container fluid className="new-car-banner">
            <Row>
                <Col xs={12}>
                    <img className="desktop" src={NewCarBanner} alt="new-car"/>
                    <img className="mobile" src={NewCarBannerMobile} alt="new-car"/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <Col lg={12} xs={12} className="new-car-title">
                    <h1>New Cars</h1>
                </Col>
            </Row>
            <Row className={"new-car-card"}>
                {
                    cars.map((car, index) => {
                        return(
                            <CarCard src_image={BASE_URL+car.picture} title={car.serie} link_detail={car.slug} key_car={index} key={index}/>
                        )
                    })
                }
            </Row>
            <Row className={endCars ? "new-car-card display-none" : "new-car-card"}  id="bottom-container">
                {(() => {
                    const options = []
                    for (let i = 0; i < 3; i++) {
                        options.push(
                            <Col lg={4} xs={12} className={`car-card-skeleton ${i > 0 && 'd-none d-sm-flex'}`}>
                                <div className="car-card-img">
                                <Skeleton className="car-card-skeleton-img"/>
                                <Skeleton height={20} className="car-card-skeleton-title" />
                                </div>
                            </Col>
                        )
                    }
                    return options
                })()}
            </Row>
        </Container>
        </>
    );
}
