import React, { useEffect, useState } from 'react'
import './EventsDetail.css'
import { Container, Row, Col, Carousel, Modal } from 'react-bootstrap'
import { Link } from "react-router-dom";
import IconShare from '../../assets/images/icon-share.png'
import IconCalendar from '../../assets/images/icon-calendar.png'
import IconCasual from '../../assets/images/icon-casual.png'
import IconFacebookCircle from '../../assets/images/icon-facebook-circle.png'
import IconWhatsappCircle from '../../assets/images/icon-whatsapp-circle1.png'
import IconInstagramCircle from '../../assets/images/icon-instagram-circle.png'
import ArrowIcon from '../../assets/images/icon-arrow.png'
import IconClose from '../../assets/images/icon-close.png'
import IconMaps from '../../assets/images/icon-maps.png'
import PrismaZoom from 'react-prismazoom'
import { useParams } from 'react-router-dom';

import { APIGET } from "../../helper/api";
import { postEventGA } from '../../helper/helper'
import { EVENT_URL, BASE_URL, FACEBOOK_URL, WHATSAPP_URL, INSTAGRAM_URL } from "../../assets/config/api";
import { getDateTimeFormat } from "../../helper/helper"
import { Helmet } from 'react-helmet';

export default function EventsDetail() {
    const currentUrl = window.location.href;
    const params = useParams()
    const [events, setEvents] = useState([])
    const [show, setShow] = useState(false)
    let param = {
        slug: params.id,
        with_pictures: true,
        token: "hjt573v3863hjkagg2ffgmllauey8832bndab",
    }
    const clickShow = () => {
        postEventGA("events/detail", "click", param.slug)
        setShow(!show)
    }
    
    
    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(EVENT_URL, param);
            setEvents(res[0])
        }
        fetchData()

    }, [])

    return (
        <>
            <Helmet>
                <title>TDA Luxury Toys | Event Detail</title>
            </Helmet>
            <Container className="titilium-container">
                <Row>
                    <Col lg={12} xs={12} className="events-detail-back">
                        <Link to='/events'>
                            <p>
                                <img src={ArrowIcon} alt="." className="icon-arrow"/>
                                EVENTS
                            </p>
                        </Link>
                    </Col>
                    <Col lg={9} xs={12} className="news-detail-title">
                        <p>{events.title}</p>
                    </Col>
                    <Col lg={3} xs={12} className="news-detail-media-social">
                        <p>SHARE</p>
                        <a rel="noopener noreferrer" href={FACEBOOK_URL} target="_blank">
                            <img src={IconFacebookCircle} alt="f"/>
                        </a>
                        <a rel="noopener noreferrer" href={INSTAGRAM_URL} target="_blank">
                            <img src={IconInstagramCircle} alt="i"/>
                        </a>
                        <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
                            <img src={IconWhatsappCircle} alt="w"/>
                        </a>
                    </Col>
                    <Col lg={12} xs={12} className="events-detail-image">
                        <img src={BASE_URL+events.picture} alt="events-detail"/>
                    </Col>
                </Row>
                <Row className="events-detail-content">
                    <Col lg={4} xs={12}>
                        <Row>
                            <Col lg={2} xs={1} 
                            className={
                                events.datetime ? 
                                "events-detail-specification"
                                :
                                "display-none"
                            }
                            >
                                <img src={IconCalendar} alt="share"/>
                            </Col>
                            <Col lg={10} xs={8}
                            className={
                                events.datetime ? 
                                "events-detail-specification"
                                :
                                "display-none"
                            }
                            >
                                <p>{ getDateTimeFormat(events.datetime) }</p>
                            </Col>
                            <Col lg={0} xs={3} className="events-detail-share">
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank">
                                    <img src={IconShare} alt="share"/>
                                </a>
                            </Col>
                            
                            <Col lg={2} xs={1} className={
                                events.dress_code ? 
                                "events-detail-specification events-detail-specification-casual"
                                :
                                "display-none"
                            }>
                                <img className="icon-casual" src={IconCasual} alt="casual"/>
                            </Col>
                            <Col lg={10} xs={11} 
                            className={
                                events.dress_code ? 
                                "events-detail-specification events-detail-specification-casual"
                                :
                                "display-none"
                            }
                            >
                                <p>{events.dress_code}</p>
                            </Col>

                            <Col lg={2} xs={1}
                            className={
                                events.address ? 
                                "events-detail-specification"
                                :
                                "display-none"
                            }
                            >
                                <img className="icon-maps" src={IconMaps} alt="maps"/>
                            </Col>
                            <Col lg={10} xs={11}
                            className={
                                events.address ? 
                                "events-detail-specification-maps"
                                :
                                "display-none"
                            }
                            dangerouslySetInnerHTML={{__html: events.address}}>
                            </Col>
                            
                            <Col lg={12} xs={12} className="events-detail-maps">
                                <div>
                                    <iframe 
                                    title={events.title}
                                    id="iframeId" 
                                    width="100%"
                                    src={`https://maps.google.com/maps?q=${events.latitude},${events.longitude}&hl=es;&output=embed`}
                                    >
                                    </iframe>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={8} xs={12} className="events-detail-description" dangerouslySetInnerHTML={{__html: events.description}}>
                    </Col>
                </Row>
            </Container>
            
            <Modal id="modal-zoom" show={show}
                fullscreen={true} centered>
                <Modal.Header>
                <Modal.Title>
                    <img src={IconClose} 
                    alt="x"
                    onClick={clickShow}
                    className="tda-search-close"
                    />
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col lg={12} xs={12} className="text-center news-detail-image">
                                <Carousel interval={null}>
                                    <Carousel.Item>
                                        <PrismaZoom className="car-detail-image-collection-modal">
                                            <img src={BASE_URL+events.picture} alt="events-detail"/>
                                        </PrismaZoom>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}
