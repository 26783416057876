import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import './SliderCar.css'
import IconArrowRight from '../../assets/images/right-arrow.png'
import { Col } from 'react-bootstrap'

import { APIGET } from "../../helper/api";
import { CAR_URL, BASE_URL, ACCESS_TOKEN } from "../../assets/config/api";


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-car-next slider-car-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-car-prev slider-car-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

const SliderCar = (props) => {
  
  
  const [carsNew, setCarNew] = useState([])
  let paramCarNew = {
      limit: 10,
      offset: 0,
      category: "new",
      token: ACCESS_TOKEN ? ACCESS_TOKEN : "",
  }

  const [carsUsed, setCarUsed] = useState([])
  let paramCarUsed = {
      limit: 10,
      offset: 0,
      category: "used",
      token: ACCESS_TOKEN ? ACCESS_TOKEN : "",
  }

  useEffect(() => {
    const fetchDataCarNew = async () => {
      const res = await APIGET(CAR_URL, paramCarNew)
      setCarNew(res)
    }
  
    const fetchDataCarUsed = async () => {
      const res1 = await APIGET(CAR_URL, paramCarUsed)
      setCarUsed(res1)
    }
    fetchDataCarNew()
    fetchDataCarUsed()
  }, [])

  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className='car-slider-container'>
        <div className='header'>
        <div>
            <h2>{props.title}</h2>
        </div>
        </div>
        <Slider {...settings} className="slider-car row">
          {
            props.title === "New Cars" ? 
              carsNew ? 
                carsNew.map((car, index) => {
                  return(
                    <Col className="slider-car-container" key={index}>
                      <a href={"/car/"+car.slug}>
                        <img src={BASE_URL+car.picture} alt={car.slug}/>
                        <h3>{car.serie}</h3>
                      </a>
                    </Col>
                  )
                })
                : <div></div>
              :
              props.title === "Used Cars" ?
              carsUsed ?
                carsUsed.map((car, index) => {
                  return(
                    <Col className="slider-car-container" key={index}>
                      <a href={"/car/"+car.slug}>
                        <img src={BASE_URL+car.picture} alt={car.slug}/>
                        <h3>{car.serie}</h3>
                      </a>
                    </Col>
                  )
                })
                :
                <div></div>
              :
              <div></div>
          }
        </Slider>
        <a
        href={
          props.title === "Used Cars" ? "used-car" : "/new-car"
        }
        >
          <button
          className={"car-slider-button button-rectangle"}
          >
              <p>DISCOVER MORE</p>
          </button>
        </a>
  </div>
  )
}

export default SliderCar