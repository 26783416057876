import React, { Component } from "react";
import Slider from "react-slick";
import './SliderServiceCenter.css';
import image1 from '../../assets/images/service-center-perawatan-berkala.jpg'
import image2 from '../../assets/images/service-center-penggantian-oli-mesin.jpg'
import image3 from '../../assets/images/service-center-perawatan-perbaikan-mobil-menyeluruh.jpg'
import image4 from '../../assets/images/service-center-pelayanan-ke-rumah.jpg'
import image5 from '../../assets/images/service-center-spooring-balancing.jpg'
import image6 from '../../assets/images/service-center-part-accessories-genuine.jpg'
import IconArrowRight from '../../assets/images/right-arrow.png'
import { Row, Col } from 'react-bootstrap'

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-service-center-next slider-service-center-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-service-center-prev slider-service-center-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

export default class SliderServiceCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }
  
  render() {
    const settings = {
      className: "center",
      infinite: true,
      speed: 500,
      arrows: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const settings2 = {
      className: "center",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      appendDots: dots => (
        <div>
          <ul className="slider-dot-ul" style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div className="slider-dot">
        </div>
      )
    };
    
    return (
      <Row>
        <Col lg={5} xs={12} className="service-center-image">
          <Slider {...settings} 
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          className="slider-service-center">
            <div className="slider-service-center-container">
              <img src={image1} alt="slider"/>
            </div>
            <div className="slider-service-center-container">
              <img src={image2} alt="slider"/>
            </div>
            <div className="slider-service-center-container">
              <img src={image3} alt="slider"/>
            </div>
            <div className="slider-service-center-container">
              <img src={image4} alt="slider"/>
            </div>
            <div className="slider-service-center-container">
              <img src={image5} alt="slider"/>
            </div>
            <div className="slider-service-center-container">
              <img src={image6} alt="slider"/>
            </div>
          </Slider>
        </Col>
        <Col lg={1} xs={12}>
        </Col>
        <Col lg={6} xs={12} className="service-center-image">
          <Slider {...settings2}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          className="slider-service-center-text">
            <div className="slider-service-center-text-container service-center-description">
              <p>
              Don't sweat it! TDA Service Center makes it easier to schedule your car maintenance. With services that are transparent, safe, comfortable and professionally served.  Because It's about excellence.
              <br /><br />
              TDA Service Centre memudahkan jadwal perawatan mobil anda, dengan pelayanan yang transparan, aman dan nyaman. Workshop kami di Kawasan Jakarta Selatan adalah “rumah” terbaik untuk mobil kesayangan anda.
              </p>
            </div>
            <div className="slider-service-center-text-container service-center-description">
              <p>
              We guarantee an effective car oil change without a long time, with various types according to the needs of the car. An engine is like the heart of a car, it needs to run smooth in order to keep your car moving. Anything you need, we are here.
              <br /><br />
              Kami menjamin penggantian oli mobil efektif tanpa waktu lama, dengan ragam jenis oli sesuai kebutuhan mobil.
              </p>
            </div>
            <div className="slider-service-center-text-container service-center-description">
              <p>
              If you spend a lot of time on the road, your car becomes more than just a means of getting from A to B. It can be as much a part of your living space as your lounge or your office. Please make yourself and you car, comfortable.
              <br /><br />
              Mencakup system listrik mobil, perbaikan suspensi, perawawatan rem hingga penggantian baterai.
              </p>
            </div>
            <div className="slider-service-center-text-container service-center-description">
              <p>
              If you too are busy, don't worry let us come to you. Service with convenience no more wasting time, our mechanics can come to you. Call us, our professional mechanic will come to your home.
              <br /><br />
              Hubungi kami, mekanik kami akan datang untuk pelayanan khusus untuk anda.
              </p>
            </div>
            <div className="slider-service-center-text-container service-center-description">
              <p>
              Spooring and balancing meet the needs of any type of car with complete equipment, the right time and place to Spooring and Balancing. Do some checking and steering components so that the vehicle spooring and balancing the maximum results.
              <br /><br />
              Spooring dan balancing dijamin sesuai kebutuhan jenis mobil, dengan peralatan lengkap.
              </p>
            </div>
            <div className="slider-service-center-text-container service-center-description">
              <p>
              If you spend a lot of time on the road, your car becomes more than just a means of getting from A to B. It can be as much a part of your living space as your lounge or your office. Please make yourself and you car, comfortable.
              <br /><br />
              Mencakup system listrik mobil, perbaikan suspensi, perawawatan rem hingga penggantian baterai.
              </p>
            </div>
          </Slider>
        </Col>
      </Row>
    );
  }
}