import React from 'react';
import './MyGarageCardCard.css';
import IconEdit from '../../assets/images/icon-edit-circle.png'
import IconDelete from '../../assets/images/icon-delete-circle.png'

export default function MyGarageCardCard(props) {
    return (
        <div className="my-garage-car-card">
            <div  className="my-garage-car-image">
                <img src={props.url_image} alt="car"/>
                <div className="my-garage-edit-delete">
                    <img src={IconEdit} 
                    className="icon-edit"
                    onClick={
                        (event) => {
                            props.onClickEdit(event)
                        } 
                    }
                    index_car={props.index_car}
                    alt="."/>
                    <img src={IconDelete} 
                    alt="."
                    
                    onClick={
                        () => {
                            props.onClickDelete(props.car_id)
                        } 
                    }
                    />
                </div>
                <div className="my-garage-see-all">
                    <p>SEE ALL IMAGES</p>
                </div>
            </div>
            <h2 title={props.title}>{props.title}</h2>
            <div className="my-garage-car-detail">
                <p>YEAR</p>
                <p>{props.year}</p>
            </div>
            <div className="my-garage-car-detail">
                <p>COLOR</p>
                <p>{props.color}</p>
            </div>
            <div className="my-garage-car-detail">
                <p>ENGINE</p>
                <p>{props.engine}</p>
            </div>
        </div>
    );
}
