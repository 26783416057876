import React from 'react';
import './MagazineCard.css';
import { Col } from 'react-bootstrap'
import { postEventGA } from '../../helper/helper'

export function MagazineCard(props) {
  return (
    <Col lg={3} xs={12} className="magazine-card w-20">
      <a href={props.source_pdf} rel="noopener noreferrer" target="_blank" onClick={() => {
        postEventGA("discover/magazine/", "click", props.title)
      }}>
        <div className="magazine-card-img">
          <img src={props.source_image} alt="."/>
        </div>
        <div>
            <h2>{props.title}</h2>
        </div>
      </a>
    </Col>
  );
}
