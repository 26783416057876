import React from 'react';
import './FormTextArea.css';
import { Form } from 'react-bootstrap'


export function FormTextArea(props) {
  return (
    <Form.Group className={props.class_extend == null ? "col-lg-12 col-xs-12" : props.class_extend } controlId="">
        <Form.Label className="form-text-area-title">{props.field_title}*</Form.Label>
        <Form.Control className="form-text-area" as="textarea" rows={props.field_row} placeholder={props.field_place_holder}/>
    </Form.Group>
  );
}
