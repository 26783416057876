import React from 'react';
import './CarCard.css';
import { Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { postEventGA } from '../../helper/helper'

export function CarCard(props) {
  return (
    <Col lg={4} xs={12}>
      <a href={"/car/"+props.link_detail} onClick={() => {
        postEventGA("car/detail", "click", props.title)
      }}>
        <div className="car-card-img">
          {<img src={props.src_image} alt="car"/>|| <Skeleton />}
        </div>
        <div>
            <h2>{props.title || <Skeleton />}</h2>
        </div>
      </a>
    </Col>
  );
}
