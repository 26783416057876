import React, { Component } from "react";
import Slider from "react-slick";
import './SliderOurHistory.css';
import IconArrowRight from '../../assets/images/right-arrow.png'
import ImageModal1 from '../../assets/images/history-1.jpg'
import ImageModal2 from '../../assets/images/history-2.jpg'
import ImageModal3 from '../../assets/images/history-3.jpg'
import ImageModal4 from '../../assets/images/history-4.jpg'
import ImageModal5 from '../../assets/images/history-5.jpg'
import ImageModal6 from '../../assets/images/history-6.jpg'
import ImageModal7 from '../../assets/images/history-7.jpg'
import ImageModal8 from '../../assets/images/history-8.jpg'
import ImageModal9 from '../../assets/images/history-9.jpg'
import ImageModal0 from '../../assets/images/history-10.jpg'
import ImageModalLineYear from '../../assets/images/line-our-history.png'
import { Row, Col } from 'react-bootstrap'

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-our-history-next slider-our-history-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="slider-our-history-prev slider-our-history-arrow"
      onClick={onClick}
    >
      <img src={IconArrowRight} alt="a" />
    </div>
  );
}

export default class SliderOurHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      nav3: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav3: this.slider3
    });
  }
  
  render() {
    const settings = {
      className: "center",
      infinite: false,
      arrows: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    const settings3 = {
      infinite: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
    }

    const settings2 = {
      className: "center",
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }
    
    return (
      <Row id="modal-our-history-row">
        <Col lg={6} xs={12} id="modal-our-history-text">
          <div>
            <h1>Our History</h1>
          </div>
          <Slider {...settings2} 
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          className="slider-service-center">
            <div className="slider-our-history-container">
              <p className="text-1">
              The dream starts with this car, The Audi TT 2008 Black. It was a challenging and rough journey back then; the company started running with only a few employees, and of course, William Tjandra, who sold this car directly to the customer.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
                THE 1ST SHOWROOM ONLY HAD THE CAPACITY OF 5 VEHICLE<br /><br />
                TDA Luxury Toys opened the doors at the first showroom located at Jl. Suryapranoto No.10, Harmoni, Central Jakarta, where people can share their passion, dreams, and experiences of Luxurious Automotive
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              As time went by - through 10 years of hard work and determination, TDA Luxury Toys finally expanded & moved to a new building as the latest HQ and showroom, located at Jl. Kramat Pela, South Jakarta. The grand opening of TDA Luxury Toys at the new location on the 28th of February 2019 was held & attended by all of the beloved customers and the most exclusive Indonesian supercar communities.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              TDA Luxury toys became the first authorized dealership for the BAC MONO supercar in Southeast Asia. This historical partnership marks our company's vision to be one step ahead in delivering extraordinary services to our luxury car enthusiasts in Indonesia.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              On January 25th, 2021, we built the 1st TDA Service Centre, which serves all premium & luxury cars from any brand. So in the future, you'll get extra serene of extra protection for your lovely vehicle.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              TDA Luxury Toys became the authorized dealer of Morgan Motor in Indonesia. The first and only primary dealer for this beautiful and sophisticated Morgan, from the Plus Four, Plus Six, and the rarest P101 Three Wheelers.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              TDA Luxury Toys became the authorized dealer of Amalgam Collection. Amalgam Collection focuses on a model of unrivaled the world’s most iconic and luxurious cars at scale.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              This year, the Jeep became part of a line of brands available through TDA Luxury Toys. Every single JEEP vehicle can be ordered through TDA with an official warranty that comes in the package.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              TDA Luxury Toys successfully brought the prestigious Mega Car Brand from Sweden, Koenigsegg, and being the first authorized dealer in Indonesia.
              </p>
            </div>
            <div className="slider-our-history-container">
              <p className="text-1">
              The journey won’t stop here, as there will be more exciting surprises in the future. Come & join us, the big family of TDA Luxury Toys. A place where you can share your automotive passion.
              </p>
            </div>
        </Slider>
        </Col>
        <Col lg={6} xs={12} id="modal-our-history-img">
          <Slider {...settings}
          asNavFor={this.state.nav3}
          ref={slider => (this.slider2 = slider)}
          className="slider-service-center-text">
            <img className="modal-our-history-img" src={ImageModal1} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal2} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal3} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal4} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal5} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal6} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal7} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal8} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal9} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal9} alt="img"/>
            <img className="modal-our-history-img" src={ImageModal0} alt="img"/>
          </Slider>
        </Col>
        <Col lg={12} xs={12} id="modal-our-history-line" >
              <img src={ImageModalLineYear} alt="img"/>
        </Col>
        <Col lg={12} xs={12} id="modal-our-history-year">
          <Slider {...settings3}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider3 = slider)}
          className="">
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2009</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2010</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2019</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2020</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2021</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2021</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2021</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2022</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">2022</p>
            </div>
            <div className="modal-our-history-year-item">
              <div className="dot"></div>
              <p className="text-2">future</p>
            </div>
          </Slider>
        </Col>
      </Row>
    );
  }
}