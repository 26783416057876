import React, { useEffect, useState }  from 'react'
import './CarDetailPdf.css'
import { Container, Row, Col } from 'react-bootstrap'

import { useParams } from 'react-router-dom';
import { APIGET } from "../../helper/api";
import { ACCESS_TOKEN, CAR_URL } from "../../assets/config/api";
import { postEventGA } from '../../helper/helper'


export default function CarDetailPdf() {

    const [globalErrorMessage, setGlobalErrorMessage] = useState("")
    const params = useParams()
    let param = {
        slug: params.id,
        with_pictures: true,
        token: ACCESS_TOKEN,
    }

    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(CAR_URL, param)
            if(res[0]){
                if(res[0].attachment_url){
                    postEventGA("qr-code", "click", param.slug)
                    window.location.href = res[0].attachment_url
                }
                else{
                    setGlobalErrorMessage("Sorry file is not available, please contact customer service")
                }
            }else{
                setGlobalErrorMessage("Sorry file is not available, please contact customer service")
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <Container>
                <Row>
                    <Col lg={12} xs={12}>
                        <h1 id="car-pdf">
                            {globalErrorMessage}
                        </h1>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
