import React, { useState } from 'react'
import './SpecialRequest.css'
import SpecialRequestBanner from '../../assets/images/special-request-banner.png'
import SpecialRequestBannerMobile from '../../assets/images/special-request-banner-mobile.png'
import IconClose from '../../assets/images/icon-close.png'
import { Form, Container, Row, Col, Modal } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import { FormTextArea } from '../../components/FormTextArea/FormTextArea'
import { postEventGA } from '../../helper/helper'

import { normalizeErrorMessage } from '../../helper/helper'
import { APIPOST } from "../../helper/api"
import { ACCESS_TOKEN, CAR_ENQUIRY_URL } from "../../assets/config/api"
import { Helmet } from 'react-helmet'

export default function SpecialRequest() {
    const [specialRequestForm, setSpecialRequestForm] = useState({
        form: {
            category: {
                value: "other_interest_car",
            },
            color: {
                value: ""
            },
            description: {
                value: ""
            },
            email: {
                value: localStorage.getItem("email") ? localStorage.getItem("email") : ""
            },
            engine: {
                value: ""
            },
            full_name: {
                value: 
                    localStorage.getItem("first_name") ?
                    localStorage.getItem("first_name")+" "+localStorage.getItem("last_name")
                    :
                    ""
            },
            manufacturer: {
                value: ""
            },
            mobile_number: {
                value: localStorage.getItem("mobile_number") ? localStorage.getItem("mobile_number") : ""
            },
            serie: {
                value: ""
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
            year: {
                value: ""
            },
        }
    });

    const [show, setShow] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")


    const setHandleValueChange = (event) => {
        let copyForm = specialRequestForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setSpecialRequestForm(copyForm)
    }

    const handleSubmit = async () => {
        const formCopy = specialRequestForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        formToSubmit['category'] = 'other_interest_car'
        let res = await APIPOST(CAR_ENQUIRY_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            postEventGA("special-request"+formToSubmit.provider, "click", "interest-car")
            setShow(true)
        }
    }

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Special Request</title>
        </Helmet>
        <Container fluid className="special-request-banner">
            <Row>
                <Col xs={12}>
                    <img className="desktop" src={SpecialRequestBanner} alt="special request"/>
                    <img className="mobile" src={SpecialRequestBannerMobile} alt="special request"/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="special-request-form">
                <Col lg={12} xs={12} className="special-request-title mb-4">
                    <h1>Special Request</h1>
                </Col>
                <Row className="justify-content-lg-center">
                    <FormField nameField="full_name" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="FULLNAME" field_type="text" field_place_holder="Type full name"/>
                    <FormField nameField="email" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                    <FormField nameField="mobile_number" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="MOBILE NUMBER" field_type="text" field_place_holder="Type mobile number"/>
                    <FormField nameField="manufacturer" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="BRAND / MANUFACTURER" field_type="text" field_place_holder="Type Brand Manufacturer"/>
                    <FormField nameField="serie" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="SERIES" field_type="text" field_place_holder="Type Series"/>
                    <FormField nameField="year" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="YEAR" field_type="text" field_place_holder="YYYY"/>
                    <FormField nameField="engine" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="ENGINE" field_type="text" field_place_holder="Type engine"/>
                    <FormField nameField="color" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="COLOR" field_type="text" field_place_holder="Type color"/>
                    <FormTextArea nameField="description" val={specialRequestForm} setHandleValueChange={setHandleValueChange} field_title="NOTE" field_type="textarea" field_row="5" field_place_holder="Type description"/>
                    <Col lg={12} xs={12}>
                        <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                            {globalErrorMessage ? globalErrorMessage : "success"}
                        </p>
                    </Col>
                    <Col lg={12} xs={12}>
                        <p className="text-center">We will contact you as soon as possible</p>
                    </Col>
                    <Form.Group className="col-lg-3 col-xs-12 justify-content-center d-flex">
                        <button
                        className={"special-request-button button-rectangle"}
                        onClick={handleSubmit}
                        name="SUBMIT"
                        >
                            <p>SUBMIT</p>
                        </button>
                    </Form.Group>
                </Row>
            </Row>
        </Container>

        
        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>
                Thank you for submit,<br />
                Our sales will contact you soon
                </p>
            </Modal.Body>
        </Modal>
        </>
    )
}
