import React from 'react';
import './ButtonRectangle.css';
// import { Link } from 'react-router-dom';

export function ButtonRectangle(props) {
  return (
    <button {...props} 
    className={props.custom_style + " button-rectangle"}
    onClick={props.on_click_function}
    >
        <p>{props.name}</p>
    </button>
  );
}
