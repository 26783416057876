import React, { useEffect, useState } from 'react'
import './NewsDetail.css'
import { Container, Row, Col, Carousel, Modal } from 'react-bootstrap'
import IconShare from '../../assets/images/icon-share.png'
import IconFacebookCircle from '../../assets/images/icon-facebook-circle.png'
import IconWhatsappCircle from '../../assets/images/icon-whatsapp-circle1.png'
import IconInstagramCircle from '../../assets/images/icon-instagram-circle.png'
import ArrowIcon from '../../assets/images/icon-arrow.png'
import IconClose from '../../assets/images/icon-close.png'
import { useParams } from 'react-router-dom';
import { DiscoverCard } from '../../components/DiscoverCard/DiscoverCard';
import PrismaZoom from 'react-prismazoom'

import { APIGET } from "../../helper/api";
import { NEWS_URL, BASE_URL, ACCESS_TOKEN, FACEBOOK_URL, WHATSAPP_URL, INSTAGRAM_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function NewsDetail() {
    const currentUrl = window.location.href;
    const params = useParams()
    const [show, setShow] = useState(false)
    const clickShow = () => {
        setShow(!show)
    }
    const [newsDetail, setNewsDetail] = useState([])
    let param = {
        slug: params.id,
        with_pictures: true,
        token: ACCESS_TOKEN,
    }

    
    const [news, setNews] = useState([])
    let param1 = {
    }
    
    
    useEffect(() => {
        const fetchData = async () => {
            const res = await APIGET(NEWS_URL, param)
            setNewsDetail(res[0])
        }
        fetchData()

        
        const fetchDataNews = async () => {
            const res = await APIGET(NEWS_URL, param1)
            setNews(res)
        }
        fetchDataNews()
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | News</title>
        </Helmet>
        <Container className="titilium-container">
            <Row>
                <Col lg={12} xs={12} className="news-back">
                    <a href='/news'>
                        <p>
                            <img src={ArrowIcon} alt="." className="icon-arrow"/>
                            NEWS
                        </p>
                    </a>
                </Col>
                <Col lg={9} xs={12} className="news-detail-title">
                    <p>{newsDetail.title}</p>
                </Col>
                <Col lg={3} xs={12} className="news-detail-media-social">
                    <p>SHARE</p>
                    <a rel="noopener noreferrer" href={FACEBOOK_URL} target="_blank">
                        <img src={IconFacebookCircle} alt="f"/>
                    </a>
                    <a rel="noopener noreferrer" href={INSTAGRAM_URL} target="_blank">
                        <img src={IconInstagramCircle} alt="i"/>
                    </a>
                    <a rel="noopener noreferrer" href={WHATSAPP_URL} target="_blank">
                        <img src={IconWhatsappCircle} alt="w"/>
                    </a>
                </Col>
                <Col lg={12} xs={12} className="news-detail-image">
                    <img onClick={clickShow} src={BASE_URL+newsDetail.picture} alt="news"/>
                </Col>
                <Col lg={0} xs={0} className="news-detail-specification">
                    <p>Zurich, 22 Feb 2022</p>
                    <p>by Arinda Purnasari</p>
                </Col>
                <Col lg={0} xs={3} className="news-detail-share">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`} target="_blank">
                        <img src={IconShare} alt="share"/>
                    </a>
                </Col>
                <Col lg={12} xs={12} className="news-detail-description" dangerouslySetInnerHTML={{__html: newsDetail.description}}>
                </Col>
            </Row>
        </Container>

        <Container fluid className="car-detail-news-collection">
            <Container>
                <Row>
                    {
                        news.map((single_news, index) => {
                            if(single_news.slug !== params.id){
                                return(
                                    <DiscoverCard updated_at={single_news.updated_at} 
                                    title={single_news.title} 
                                    picture={single_news.picture}
                                    key={index}
                                    slug={single_news.slug} 
                                    card_type="/news/"/>
                                )
                            }
                            return ""
                        })
                    }
                </Row>
            </Container>
        </Container>

        <Modal id="modal-zoom" show={show}
            fullscreen={true} centered>
            <Modal.Header>
            <Modal.Title>
                <img src={IconClose} 
                alt="x"
                onClick={clickShow}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={12} xs={12} className="text-center news-detail-image">
                            <Carousel interval={null}>
                                <Carousel.Item>
                                    <PrismaZoom className="car-detail-image-collection-modal">
                                        <img src={BASE_URL+newsDetail.picture} alt="news"/>
                                    </PrismaZoom>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
        </>
    );
}
