import React, { useState } from 'react'
import './Register.css'
import { Container, Row, Col } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import ArrowIcon from '../../assets/images/icon-arrow.png'
import {useNavigate} from 'react-router-dom'
import { postEventGA } from '../../helper/helper'

import { normalizeErrorMessage } from '../../helper/helper'
import { API_POST_AUTH } from "../../helper/api"
import { ACCESS_TOKEN, REGISTER_URL, BASE_URL } from "../../assets/config/api"
import { Helmet } from 'react-helmet'

export default function Register() {
    const [registerForm, setRegisterForm] = useState({
        form: {
            first_name: {
                value: '',
            },
            last_name: {
                value: ''
            },
            email: {
                value: ''
            },
            mobile_number: {
                value: ''
            },
            password: {
                value: ''
            },
            confirm_password: {
                value: ''
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        }
    })

    const navigate = useNavigate()
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")

    
    const handleSubmit = async () => {
        const formCopy = registerForm.form
        let formToSubmit = {}
        for (let key in formCopy) {
            formToSubmit[key] = formCopy[key].value
        }
        let res = await API_POST_AUTH(REGISTER_URL, formToSubmit)
        let globalErrorMessageResponse
        if("detail" in res.payload){
            globalErrorMessageResponse = res.payload.detail
            setGlobalErrorMessage(normalizeErrorMessage(globalErrorMessageResponse))
        }else if("result" in res.payload){
            globalErrorMessageResponse = res.payload.result
            setGlobalErrorMessage(normalizeErrorMessage(globalErrorMessageResponse))
        }else{
            localStorage.setItem('session_key', res.payload.session_key)
            let userFormRes  = res.payload.user
            
            postEventGA("register/", "click",  userFormRes.username)
            localStorage.setItem('username', userFormRes.username)
            localStorage.setItem('first_name', userFormRes.first_name)
            localStorage.setItem('last_name', userFormRes.last_name)
            localStorage.setItem('email', userFormRes.email)
            localStorage.setItem('mobile_number', userFormRes.mobile_number)
            localStorage.setItem('short_benefit_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('name_membership', userFormRes.membership.short_benefit)
            localStorage.setItem('image_membership', BASE_URL+userFormRes.membership.bg_card)
            navigate('/my-profile')
        }
    }

    const setHandleValueChange = (event) => {
        let copyForm = registerForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setRegisterForm(copyForm)
    }

    return (
        <>
            <Helmet>
                <title>TDA Luxury Toys | Register</title>
            </Helmet>
            <Container className="register-container">
                <Row className="register-form">
                    <Col lg={12} xs={12} className="register-back" style={{ zIndex: 999 }}>
                        <a href='/login'>
                            <p>
                                <img src={ArrowIcon} alt="." className="icon-arrow"/>
                                LOGIN
                            </p>
                        </a>
                    </Col>
                    <Col lg={12} xs={12} className="register-title">
                        <h1>Register</h1>
                        <p>TDA bring you one step closer into the world of he toys</p>
                    </Col>
                </Row>
                <Row>
                    <Row className="justify-content-center">
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="first_name" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="FIRST NAME" field_type="text" field_place_holder="Type first name"/>
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="last_name" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="LAST NAME" field_type="text" field_place_holder="Type last name"/>
                    </Row>
                    <Row className="justify-content-center">
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="mobile_number" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="PHONE NUMBER" field_type="text" field_place_holder="Type phone number"/>
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="email" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                    </Row>
                    <Row className="justify-content-center">
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="password" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="PASSWORD" field_type="password" field_place_holder="Type password"/>
                        <FormField class_extend="col-lg-5 col-xs-12" nameField="confirm_password" val={registerForm} setHandleValueChange={setHandleValueChange} field_title="CONFIRM PASSWORD" field_type="password" field_place_holder="Retype password"/>
                    </Row>
                    <Row className="justify-content-lg-center">
                        <Col lg={10} xs={12}>
                            <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                                {globalErrorMessage ? globalErrorMessage : "success"}
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-lg-center">
                        <Col className="col-lg-3 col-xs-12">
                            <button
                            className={"register-button button-rectangle"}
                            onClick={handleSubmit}
                            >
                                <p>REGISTER</p>
                            </button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </>
    );
}
