import React, { useState } from 'react'
import './Sell.css'
import SellRequestBanner from '../../assets/images/sell-banner.png'
import SellRequestBannerMobile from '../../assets/images/sell-banner-mobile.png'
import IconClose from '../../assets/images/icon-close.png'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { FormField } from '../../components/FormField/FormField'
import { FormFieldImage } from '../../components/FormFieldImage/FormFieldImage'
import { FormTextArea } from '../../components/FormTextArea/FormTextArea'
import { postEventGA } from '../../helper/helper'

import { normalizeErrorMessage } from '../../helper/helper'
import { APIPOSTFILES } from "../../helper/api"
import { ACCESS_TOKEN, CAR_ENQUIRY_URL } from "../../assets/config/api"
import { Helmet } from 'react-helmet'

export default function Sell() {
    
    const [sellForm, setSellForm] = useState({
        form: {
            id: {
                value: '',
            },
            manufacturer: {
                value: '',
            },
            serie: {
                value: '',
            },
            year: {
                value: '',
            },
            color: {
                value: '',
            },
            engine: {
                value: '',
            },
            email: {
                value: '',
            },
            mobile_number: {
                value: '',
            },
            mileage: {
                value: '',
            },
            price: {
                value: '',
            },
            description: {
                value: '',
            },
            tax_year: {
                value: new Date(),
                origin: ''
            },
            full_name: {
                value: 
                localStorage.getItem("first_name") ?
                localStorage.getItem("first_name")+" "+localStorage.getItem("last_name")
                :
                "aaa",
            },
            image_1: {
                preview: '',
                value: ''
            },
            image_2: {
                preview: '',
                value: ''
            },
            image_3: {
                preview: '',
                value: '',
            },
            image_4: {
                preview: '',
                value: '',
            },
            image_5: {
                preview: '',
                value: '',
            },
            image_6: {
                preview: '',
                value: '',
            },
            image_7: {
                preview: '',
                value: '',
            },
            image_8: {
                preview: '',
                value: '',
            },
            image_9: {
                preview: '',
                value: '',
            },
            image_10: {
                preview: '',
                value: '',
            },
            delete_image_ids: {
                value: ''
            },
            session_key: {
                value: localStorage.getItem("session_key") ? localStorage.getItem("session_key") : ""
            },
            token: {
                value: ACCESS_TOKEN ? ACCESS_TOKEN : ""
            },
        },
    })
    
    const [show, setShow] = useState(false)
    const [globalErrorMessage, setGlobalErrorMessage] = useState("")

    const setHandleValueChange = (event) => {
        let copyForm = sellForm
        copyForm["form"][event.target.name]["value"] = event.target.value
        setSellForm(copyForm)
    }

    const handleSubmit = async () => {
        let formToSubmit = new FormData()
        for (let key in sellForm.form) {
            formToSubmit.append(key, sellForm.form[key].value)
        }
        formToSubmit.append('category', 'buy_my_car')
        let res = await APIPOSTFILES(CAR_ENQUIRY_URL, formToSubmit)
        if (res.detail) {
            setGlobalErrorMessage(normalizeErrorMessage(res.detail))
        } else {
            postEventGA("sell", "click", 'buy-my-car')
            setShow(true)
        }
    }
    
    const handleChangeImage = (event) => {
        if (event.target.files.length) {
            let copyForm = { ...sellForm }
            copyForm["form"][event.target.id].preview = URL.createObjectURL(event.target.files[0])
            copyForm["form"][event.target.id].value = event.target.files[0]
            setSellForm(copyForm)
        }
    }

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Sell</title>
        </Helmet>
        <Container fluid className="special-request-banner">
            <Row>
                <Col xs={12}>
                    <img className="desktop" src={SellRequestBanner} alt="special request"/>
                    <img className="mobile" src={SellRequestBannerMobile} alt="special request"/>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className="special-request-form">
                <Col lg={12} xs={12} className="special-request-title">
                    <h1>Sell Your Car</h1>
                </Col>
                <Row>
                    <FormField nameField="full_name" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="FULLNAME" field_type="text" field_place_holder="Type full name"/>
                    <FormField nameField="email" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="EMAIL" field_type="email" field_place_holder="Type email"/>
                    <FormField nameField="mobile_number" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="MOBILE NUMBER" field_type="text" field_place_holder="Type mobile number"/>
                    <FormField nameField="manufacturer" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="BRAND / MANUFACTURER" field_type="text" field_place_holder="Type brand / manufacturer"/>
                    <FormField nameField="serie" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="SERIES" field_type="text" field_place_holder="Type series"/>
                    <FormField nameField="year" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="YEAR" field_type="text" field_place_holder="YYYY"/>
                    <FormField nameField="engine" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="ENGINE" field_type="text" field_place_holder="Type engine"/>
                    <FormField nameField="color" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="COLOR" field_type="text" field_place_holder="Type color"/>
                    <FormField nameField="mileage" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="MILEAGE" field_type="text" field_place_holder="Type mileage"/>
                    <FormField nameField="tax_year" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="STNK / TAX EXPIRED" field_type="date" field_place_holder=""/>
                    <FormField nameField="price" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="SELL PRICE" field_type="text" field_place_holder="Type price"/>
                    <FormTextArea nameField="description" val={sellForm} setHandleValueChange={setHandleValueChange} field_title="NOTE" field_type="textarea" field_row="5" field_place_holder="Type description"/>
                </Row>
                <Row>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_1"
                            val={sellForm}
                            path={sellForm.form.image_1.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_2"
                            val={sellForm}
                            path={sellForm.form.image_2.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_3"
                            val={sellForm}
                            path={sellForm.form.image_3.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_4"
                            val={sellForm}
                            path={sellForm.form.image_4.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_5"
                            val={sellForm}
                            path={sellForm.form.image_5.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_6"
                            val={sellForm}
                            path={sellForm.form.image_6.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_7"
                            val={sellForm}
                            path={sellForm.form.image_7.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_8"
                            val={sellForm}
                            path={sellForm.form.image_8.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_9"
                            val={sellForm}
                            path={sellForm.form.image_9.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                    <Col lg={2} xs={4} className="sell-image-item" >
                        <FormFieldImage 
                            nameField="image_10"
                            val={sellForm}
                            path={sellForm.form.image_10.preview}
                            handleChangeImage={handleChangeImage}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-lg-center">
                    <Col lg={12} xs={12}>
                        <p className={globalErrorMessage ? "error-message" : "text-opacity-zero"}>
                            {globalErrorMessage ? globalErrorMessage : "success"}
                        </p>
                    </Col>
                    <Col lg={12} xs={12}>
                        <p className="text-center">We will contact you as soon as possible</p>
                    </Col>
                    <Col lg={3} xs={12} className="justify-content-center d-flex">
                        <button
                        className={"special-request-button button-rectangle"}
                        onClick={handleSubmit}
                        name="SUBMIT"
                        >
                            <p>SUBMIT</p>
                        </button>
                    </Col>
                </Row>
            </Row>
        </Container>

        <Modal id="modal-close-info" show={show} centered>
            <Modal.Header>
            <Modal.Title>
                <p>Info</p>
                <img src={IconClose} 
                alt="x"
                onClick={() => window.location.reload(false)}
                className="tda-search-close"
                />
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>
                Thank you for submit,<br />
                Our sales will contact you soon
                </p>
            </Modal.Body>
        </Modal>
        </>
    );
}
