import React from 'react'
import './Wheels.css'
import { Container, Row, Col } from 'react-bootstrap'
import IconGooglePlay from '../../assets/images/icon-google-play.png'
import IconAppStore from '../../assets/images/icon-app-store.png'
import { GOOGLE_URL, APPLE_URL } from "../../assets/config/api";
import { postEventGA } from '../../helper/helper'
import { Helmet } from 'react-helmet'

export default function Wheels() {
    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Wheels</title>
        </Helmet>
        <Container className="wheels-container">
            <Row>
                <Col lg={12} xs={12} className="my-profile-title">
                    <h1>Wheels</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={12}>
                    <p className="wheels-description">
                        Wheel Feature is only available on our App. <br />Please download in App Store or Google Play
                    </p>
                    <div className="wheels-images">
                        <a href={GOOGLE_URL} target="_blank" rel="noopener noreferrer" onClick={() => {
                            postEventGA("wheels", "click", "google-play")
                        }}>
                            <img src={IconGooglePlay} alt="google play"/>
                        </a>
                        <a href={APPLE_URL} target="_blank" rel="noopener noreferrer"
                        onClick={() => {
                            postEventGA("wheels", "click", "app store")
                        }}>
                            <img src={IconAppStore} alt="app store"/>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
}
