import React, { useState, useEffect } from 'react'
import { Row, Col, Navbar, Container, Nav, NavDropdown, DropdownButton, ButtonGroup, Modal } from 'react-bootstrap'
import './NavbarDesktop.css'
import { CarCard } from '../CarCard/CarCard'
import TdaLogoDesktop from '../../assets/images/tda-logo-desktop.png'
import IconSearch from '../../assets/images/icon-search.png'
import IconNotification from '../../assets/images/icon-notification.png'
import IconClose from '../../assets/images/icon-close.png'
import "aos/dist/aos.css";

import { useLocation, useNavigate } from 'react-router-dom'
import { FormField } from '../FormField/FormField'

import ReactGA from 'react-ga'
import { APIGET, APIPOST } from "../../helper/api"
import { CAR_URL, BASE_URL, ACCESS_TOKEN, BRAND_URL, MERCHANDISE_URL, NOTIFICATION_URL } from "../../assets/config/api"

function NavbarDesktop(props) {

    const location = useLocation().pathname.split("/")[1]
    const slugForIconBlack = ["events", "news","magazine", "wheels", "membership", 
    "login", "register", "forgot-password", "car", "my-profile", "my-garage"]
    const [showSearch, setShowSearch] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [textSearch, setTextSearch] = useState({
        form: {
            query: {
                value: '',
            },
        },
    })
    
    const [y, setY] = useState(window.scrollY)

    const navigate = useNavigate()
    const handleClickLogout = () => {
        localStorage.clear()
        navigate('/login')
    }
    
    const [cars, setCar] = useState()
    let param = {
        query: "",
        limit: 50,
        offset: 0,
        token: ACCESS_TOKEN,
    }

    const setHandleValueChange = (event) => {
        let copyForm = textSearch
        copyForm["form"][event.target.name]["value"] = event.target.value
        setTextSearch(copyForm)

        if (event.key === 'Enter') {
            fetchData()
        }
    }

    const fetchData = async () => {
        param.query = textSearch.form.query.value
        const res = await APIGET(CAR_URL, param)
        setCar(res)
    }

    const handleNavigation = 
        e => {
        setY(window.scrollY)
    }
    
    const [responseBrand, setResponseBrand] = useState([])
    const [responseProducts, setResponseProducts] = useState([])
    let param_brand = {
        token: ACCESS_TOKEN,
    }
    let session_key = {
        session_key: localStorage.getItem("session_key"),
    }

    const fetchDataNotifications = async () => {
        const res = await APIPOST(NOTIFICATION_URL, session_key)
        window.location.reload(false)
    }
    
    useEffect(() => {
        let notificationEmpty = true
        if(localStorage.getItem("notifications")){
            if(localStorage.getItem("notifications") !== ''){
                JSON.parse(localStorage.getItem("notifications")).map((notification, index) => {
                    if(notification.status === "Sent"){
                        notificationEmpty = false
                    }
                    return ''
                })
            }
        }
        if(notificationEmpty){
            localStorage.setItem('notifications', '')
        }

        if(localStorage.getItem("session_key") === "undefined"){
            localStorage.clear()
        }
        const fetchDataProducts = async () => {
            const res = await APIGET(MERCHANDISE_URL, param_brand)
            setResponseProducts(res)
        }
        fetchDataProducts()
        const fetchDataBrand = async () => {
            const res = await APIGET(BRAND_URL, param_brand)
            setResponseBrand(res)
        }
        fetchDataBrand()
        window.addEventListener("scroll", handleNavigation)

        // const TRACKING_ID = "UA-117747563-2"
        // const path = window.location.pathname
        // ReactGA.initialize(TRACKING_ID)
        // ReactGA.pageview(path)
        
    }, [y]);

    return (
        <>
            <Navbar data-aos="fade-up" data-aos-offset="0" data-aos-easing="ease-in-sine" data-aos-delay="1000" data-aos-duration="1000"  expand="lg" className="tda-navbar-desktop" id={y === 0 ? "" : "tda-navbar-scroll"}>
                <Container fluid className="tda-navbar-desktop-container mt-md-2 pb-md-1">
                    <Navbar.Brand href="/">
                        <img className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-logo icon-black" : "tda-navbar-desktop-logo"} src={TdaLogoDesktop} alt="tda"/>
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav" className="tda-navbar-desktop-item-collection">
                        <Nav className="tda-navbar-desktop-flex mt-0">
                            <Nav.Link  className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"}  href="/">HOME</Nav.Link>
                            <NavDropdown className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black tda-navbar-cars-dropdown" : "tda-navbar-desktop-item hover-underline-animation tda-navbar-cars-dropdown"} title="CARS">
                                    <DropdownButton
                                        as={ButtonGroup}
                                        key={"end"}
                                        id={
                                            responseBrand ? 
                                            `dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand`+responseBrand.length
                                            :
                                            'dropdown-button-drop-end tda-navbar-dropdown-end dropdown-cars-brand0'
                                        }
                                        drop={"end"}
                                        title={`AUTHORIZED DEALER`}
                                    >
                                        <div className="row dropdown-brand-collection">
                                            {
                                                responseBrand ? 
                                                responseBrand.map((brand, index) => {
                                                    if(index % 2 === 0){
                                                        return(
                                                            <div className="dropdown-brand-item" key={index}>
                                                                <a href={"/brand/"+brand.title}>
                                                                    <img src={BASE_URL+brand.logo} alt={brand.title}></img>
                                                                    <p>{brand.title}</p>
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    return ""
                                                })
                                                :
                                                ""
                                            }
                                        </div>
                                        <div className="row dropdown-brand-collection">
                                            {
                                                responseBrand ? 
                                                responseBrand.map((brand, index) => {
                                                    if(index % 2 === 1){
                                                        return(
                                                            <div className="dropdown-brand-item" key={index}>
                                                                <a href={"/brand/"+brand.title}>
                                                                    <img src={BASE_URL+brand.logo} alt={brand.title}></img>
                                                                    <p>{brand.title}</p>
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    return ""
                                                })
                                                :
                                                ""
                                            }
                                        </div>
                                    </DropdownButton>
                                <NavDropdown.Item href="/new-car">NEW CARS</NavDropdown.Item>
                                <NavDropdown.Item href="/used-car">USED CARS</NavDropdown.Item>
                                <NavDropdown.Item href="/special-request">SPECIAL REQUEST</NavDropdown.Item>
                                <NavDropdown.Item href="/sell">SELL YOUR CAR</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown id="dropdown-merchandise-brand"  className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"} title="PRODUCTS">
                            {
                                    responseProducts ? 
                                    responseProducts.map((category, index) => {
                                        return(
                                            <DropdownButton
                                                as={ButtonGroup}
                                                key={"end"}
                                                className={category.merchandises.length > 8 ? "overflow-y-scroll" : ""}
                                                id={`dropdown-button-drop-end tda-navbar-dropdown-end dropdown-products-${index}`}
                                                drop={"end"}
                                                title={category.name.toUpperCase()}>
                                            <div className="row dropdown-brand-collection">
                                                    {
                                                        category.merchandises ? 
                                                        category.merchandises.map((merchandise, indexa) => {
                                                            return(
                                                                <div className="col-4 dropdown-brand-item" key={indexa}>
                                                                    <a rel="noopener noreferrer" href={merchandise.url} target="_blank">
                                                                        <img src={BASE_URL+merchandise.picture} alt={merchandise.slug}></img>
                                                                        <p>{merchandise.title.toUpperCase()}</p>
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        ""
                                                    }
                                            </div>
                                            </DropdownButton>
                                        )
                                    })
                                    :
                                    ""
                                }
                            </NavDropdown>
                            <Nav.Link  className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"} href="/service-center">TDA SERVICE CENTER</Nav.Link>
                            <NavDropdown  className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"}  title="DISCOVER">
                                <NavDropdown.Item href="/events">EVENTS</NavDropdown.Item>
                                <NavDropdown.Item href="/news">NEWS</NavDropdown.Item>
                                <NavDropdown.Item href="/magazine">MAGAZINE</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"}  href="/wheels">WHEELS</Nav.Link>
                            <Nav.Link className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item hover-underline-animation-black text-black" : "tda-navbar-desktop-item hover-underline-animation"}  href="/membership">MEMBERSHIP</Nav.Link>
                            
                        </Nav>
                    </Navbar.Collapse>
                    <div className="tda-navbar-desktop-login-register mt-0">
                        <img className={slugForIconBlack.includes(location) || y !== 0 ? "icon-black search-icon" : " search-icon"} src={IconSearch} alt="l" onClick={() => setShowSearch(true)}></img>
                        <div id="vertical-line-search">

                        </div>
                        <a className={slugForIconBlack.includes(location) || y !== 0 ? "text-black" : ""}  href="/login">
                            <p className={ localStorage.getItem("session_key") ? "display-none" : ""}>LOGIN / REGISTER</p>
                        </a>
                        <div className={ localStorage.getItem("session_key") ? "tda-navbar-desktop-login-complete" : "display-none"}>
                            <div className='position-relative'>
                                <img className={slugForIconBlack.includes(location) || y !== 0 ? "icon-black icon-notification" : "icon-white icon-notification"} src={IconNotification} 
                                onClick={() => setShowNotification(true)} alt="l"></img>
                                <div className={
                                    localStorage.getItem("notifications")  ?
                                    localStorage.getItem("notifications") === '' ? "d-none" : "bullet-notification position-absolute rounded-circle" : "d-none" }></div>
                            </div>
                            <div className="tda-navbar-desktop-login-complete-photo">
                                {
                                    localStorage.getItem("first_name") ?
                                    localStorage.getItem("first_name").charAt(0).toUpperCase()
                                    :
                                    ""
                                }
                            </div>
                            <NavDropdown  className={slugForIconBlack.includes(location) || y !== 0 ? "tda-navbar-desktop-item tda-navbar-desktop-login-complete text-black" : "tda-navbar-desktop-login-complete tda-navbar-desktop-item"}  
                            title=
                            {
                                    localStorage.getItem("first_name") ?
                                    localStorage.getItem("first_name")
                                    :
                                    ""
                            }>
                                <NavDropdown.Item href="/my-profile">My Profile</NavDropdown.Item>
                                <NavDropdown.Item href="/my-garage">My Garage</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={handleClickLogout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </div>
                </Container>
            </Navbar>

            <Modal id="modal-search" show={showSearch} fullscreen={true} onHide={() => setShowSearch(false)}>
                <Modal.Header>
                <Modal.Title>
                    <img src={IconClose} 
                    alt="x"
                    onClick={() => setShowSearch(false)}
                    className="tda-search-close"
                    />
                    <FormField class_extend="col-lg-12 col-xs-12 tda-form-control tda-search" nameField="query" val={textSearch} setHandleValueChange={setHandleValueChange} field_title="" field_type="text" field_place_holder="Search..."/>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid onClick={() => setShowSearch(false)}
                    className={
                        cars ? "display-none" : ""
                    }>
                        <Row>
                            <Col lg={12} id="search-not-yet-body">

                            </Col>
                        </Row>
                    </Container>

                    <Container
                    fluid
                    className={
                        cars ? "search-body-result" : "display-none"
                    }
                    >
                        <Container>
                            <Row className="new-car-card">
                                {
                                    cars ? 
                                    cars.map((car, index) => {
                                        return(
                                            <CarCard src_image={BASE_URL+car.picture} title={car.serie} link_detail={car.slug} key_car={index} key={index}/>
                                        )
                                    })
                                    :
                                    ""
                                }
                            </Row>
                        </Container>
                    </Container>
                </Modal.Body>
            </Modal>

            <Modal 
                id="modal-notification" 
                show={showNotification}
                onHide={() => setShowNotification(false)}
                fullscreen={true}>
                <Modal.Header closeButton className="my-garage-modal my-modal-notification">
                    
                </Modal.Header>
                <Container className="mt-5">
                    <Row className="my-garage-form my-modal-notification">
                        <Col lg={2} xs={8} className="my-garage-title" id="my-garage-modal-notification" >
                            <h1>Notification</h1>
                        </Col>
                        <Col lg={2} xs={4} className="my-notification-clear-all">
                            <button className="bg-transparent" onClick={() => {
                                localStorage.setItem('notifications', '')
                                fetchDataNotifications()
                            }}>clear all</button>
                        </Col>
                    </Row>
                </Container>
                <Modal.Body>
                    <Container>
                        <Row>
                            {
                                localStorage.getItem("notifications")  ?
                                    localStorage.getItem("notifications") !== '' ?
                                    JSON.parse(localStorage.getItem("notifications")).map((notification, index) => {
                                        
                                        return(
                                            notification.status === "Sent" ?
                                            <Col xs={12} className="my-notification-item" key={index}>
                                                    <div className="title">
                                                        <a className="font-weight-bold" href={notification.payload ? notification.payload.url ? notification.payload.url : "#"  : "#"}>
                                                            <p>{notification.title}</p>
                                                        </a>
                                                    </div>
                                                    <div className="description">
                                                        <p>{notification.message}</p>
                                                    </div>
                                                
                                            </Col>
                                            :
                                            ""
                                        )
                                    })
                                    :
                                    ""
                                :
                                ""
                            }
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NavbarDesktop;