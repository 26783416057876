import React, { useEffect, useState } from 'react'
import './Events.css'
import { Container, Row, Col } from 'react-bootstrap'
import { DiscoverCard } from '../../components/DiscoverCard/DiscoverCard'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { APIGET } from "../../helper/api";
import { EVENT_URL } from "../../assets/config/api";
import { Helmet } from 'react-helmet'

export default function Events() {

    const [ends, setEnd] = useState(false)
    const [events, setEvents] = useState([])
    let count = 0
    let param = {
        limit: 15,
        offset: 0,
    }

    
    const fetchData = async () => {
        if(!ends){
            const res = await APIGET(EVENT_URL, param)
            if(res.length === count){
                setEnd(true)
            }else{
                setEvents(res)
                count = res.length
            }
        }
    }
    
    const handleNavigation = () => {
        if(window.innerHeight > document.getElementById('bottom-container').getBoundingClientRect().bottom - 220){
            param.limit+=3
            fetchData()
        }
    }
    
    useEffect(() => {
        fetchData()
        window.addEventListener("scroll", handleNavigation)
    }, [])

    return (
        <>
        <Helmet>
            <title>TDA Luxury Toys | Events</title>
        </Helmet>
        <Container>
            <Row>
                <Col lg={12} xs={12} className="events-title">
                    <h1>Events</h1>
                </Col>
            </Row>
            <Row className="events-card">
                {
                    events ?
                    events.map((single_events, index) => {
                        return(
                            <DiscoverCard updated_at={single_events.datetime} 
                            title={single_events.title} 
                            picture={single_events.picture}
                            slug={single_events.slug} 
                            card_type="/events/"
                            key={index}/>
                        )
                    })
                    :
                    ""
                }
            </Row>
            
            <Row className={ends ? "new-car-card display-none" : "new-car-card"}  id="bottom-container">
                {(() => {
                    const options = []
                    for (let i = 0; i < 6; i++) {
                        options.push(
                            <Col lg={4} xs={12} className={`car-card-skeleton ${i > 0 && 'd-none d-sm-flex'}`}>
                                <div className="car-card-img">
                                <Skeleton className="car-card-skeleton-img"/>
                                <Skeleton height={20} className="car-card-skeleton-title" />
                                </div>
                            </Col>
                        )
                    }
                    return options
                })()}
            </Row>
        </Container>
        </>
    );
}
